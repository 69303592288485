import { ErrorHandler, Injectable } from '@angular/core';

import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(public loggingService: LoggingService,
    public notificationService: NotificationService) {
  }

  handleError(error: any): void {
    //  just log it to the console and TrackJS will pick it up
    console.error(error);
  }

}
