import { Injectable, inject } from '@angular/core';

import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { Capacitor } from '@capacitor/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';
import { LocationService } from './location.service';

@Injectable({ providedIn: 'root' })
export class PermissionsService {

  private diagnostic = inject(Diagnostic);
  private locationService = inject(LocationService);
  private get isNative(): boolean { return Capacitor.isNativePlatform(); }
  private get isAndroid(): boolean { return Capacitor.getPlatform() === 'android'; }
  private get isIOS(): boolean { return Capacitor.getPlatform() === 'ios'; }

  /**
   * checks the permissions the app needs to run
   * and returns whether all necessary permissions have been granted
   **/
  public async hasAllNecessaryPermissions(): Promise<PermissionSummary> {
    try {
      //  all settled so that we don't want all the calls to fail if 1 does
      const permissions = await Promise.allSettled([
        this.hasBackgroundLocationPermissions(),
        this.hasCameraPermissions(),
        this.hasDisplayOverOther(),
        this.hasLocationPermissions(),
        //  Ignoring physical activity for now.
        //  this.hasPhysicalActivity(),
      ])
      .then(responses => responses.map(res => res.status === 'fulfilled' ? res.value : null));
      const [ backgroundLocation, camera, displayOverOther, location ] = permissions;
      return {
      //  can't get background location from ios because diagnostic plugin isn't correctly configured for IOS
      //  TODO: fix ionic native import to allow IOS to get background info accurately -- requires a binary update
        hasAllNecessaryPermissions: this.isAndroid
          ? ![displayOverOther, backgroundLocation, location].some(value => !value)
          : ![displayOverOther, location].some(value => !value),
        permissions: { backgroundLocation, camera, displayOverOther, location, physicalActivity: null },
      };
    } catch (err) {
      console.error(err);
      return {
        hasAllNecessaryPermissions: true,
        permissions: { backgroundLocation: null, camera: null, displayOverOther: null, location: null, physicalActivity: null },
      };
    }
  }

  public hasPermission(permission: AppPermission): Promise<boolean> {
    switch(permission) {
      case 'backgroundLocation': return this.hasBackgroundLocationPermissions();
      case 'location': return this.hasLocationPermissions();
      case 'camera': return this.hasLocationPermissions();
      case 'displayOverOther': return this.hasDisplayOverOther();
      //  TODO: implement physical activity permission check
      case 'physicalActivity': throw new Error('physical activity permission check is not implemented');
      default: throw new Error(`${permission} check not found`);
    }
  }

  private async hasLocationPermissions() {
    if (!this.isNative) {
      const position = await new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          position => resolve(position),
          error => { console.error(error); resolve(null); },
        );
      });
      return !!position;
    }
    const status = await this.diagnostic.getLocationAuthorizationStatus();
    return status === 'GRANTED' || status === 'authorized';
  }

  private async hasBackgroundLocationPermissions(): Promise<boolean> {
    //  although not technically correct, no such thing in web really
    if (!this.isNative) { return true; }
    //  ios can't figure out how to find authorization statuses
    if (this.isIOS) { return null; }
    const status = await this.diagnostic.getLocationAuthorizationStatuses();
    return status.ACCESS_BACKGROUND_LOCATION === 'GRANTED';
  }

  private async hasCameraPermissions(): Promise<boolean> {
    if (!this.isNative) { return true; }
    const permission = await this.diagnostic.isCameraAuthorized();
    return permission;
  }

  private async hasDisplayOverOther(): Promise<boolean> {
    if (!this.isAndroid ) { return true; }
    const permission = await ForegroundService.checkManageOverlayPermission();
    return permission.granted;
  }

  //  TODO: For this to work we will need to install  @ionic-native/diagnostic
  // public async hasPhysicalActivity(): Promise<boolean> {
  //   if (!this.isNative ) { return true; }
  //   const permission = await this.diagnostic.isMotionAvailable();
  //   console.log('---------------------physical activity', permission);
  //   return permission;
  // }
}

export const appPermissions = [
  'backgroundLocation',
  'camera',
  'displayOverOther',
  'location',
  'physicalActivity',
] as const;
export type AppPermissions = typeof appPermissions
export type AppPermission = AppPermissions[number]
export type PermissionSummary = {
  hasAllNecessaryPermissions: boolean,
  permissions: {
    backgroundLocation: boolean,
    camera: boolean,
    displayOverOther: boolean,
    location: boolean,
    physicalActivity: boolean,
  }
}
