import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { Job, JobPerform, JobType } from '../../models/job';
import { LoadingController, ModalController } from '@ionic/angular';
import { NoShowOptions, NoShowReasonEnum, NoShowReasons } from '../../models/no-show-option';
import { PassDetails, PassInformationPageResultEnum, isCashPass, isFlexPass } from '../../models/pass';

import { AlertService } from '../../services/alert.service';
import { AppData } from 'src/app/data';
import { DeviceService } from '../../services/device.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { LocationService } from '../../services/location.service';
import { NativePluginService } from '../../services/plugin/native-plugins.service';
import { PassInformationPage } from '../pass-information/pass-information.page';
import { RouteProviderService } from '../../services/route-provider.service';
import { Router } from '@angular/router';
import { SignaturePage } from '../signature/signature.page';
import { TranslateService } from '../../services/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-perform',
  templateUrl: './job-perform.page.html',
  styleUrls: ['./job-perform.page.scss'],
})
export class JobPerformPage implements OnInit {

  @Input() job: Job;
  @Input() jobPerform: JobPerform;

  public data = inject(AppData);
  public router = inject(Router);
  public modalController = inject(ModalController);
  public routeProvider = inject(RouteProviderService);
  public locationService = inject(LocationService);
  private errorHandler = inject(ErrorHandlerService);
  private deviceService = inject(DeviceService);
  private dispatcherService = inject(DispatcherService);
  private loadingCtrl = inject(LoadingController);
  private alertService = inject(AlertService);
  private translateService = inject(TranslateService);
  private pluginService = inject(NativePluginService);

  backupOdometer: number;
  tempOdometer: number = this.data.gpsReport?.odometer || 0;
  JobTypeEnum = JobType;
  noShowReason = NoShowReasonEnum;
  noShowOptions: NoShowOptions[];
  passInformation: PassDetails;
  passHadSufficientFunds = false;
  isUsingPassInSomeRegard = false;
  backButtonVisible = true;
  paraPassEnabled = this.data.paraPassEnabled;
  clickEnabled = true;
  passInformationDisplayStrings = [];
  showFare = environment.features.calculateJobFare;
  public readonly features = environment.features;
  public readonly isFlexPass = isFlexPass;
  public readonly isCashPass = isCashPass;

  async ngOnInit() {

    this.noShowOptions = this.getTranslatedNoShowOptionList(this.job);

    if (this.job && this.job.riderSignatureRequired && !this.jobPerform.noShowReason) {
      await this.captureSignature();
    } else {
      await this.scanEPasIfAvailable();
    }

    if (this.data.fareTypes && this.data.fareTypes.length > 0) {
      this.jobPerform.fareTypeId = this.jobPerform.fareTypeId || this.data.fareTypes[0]?.id;
    }
  }

  async scanEPasIfAvailable(): Promise<void> {
    if (
      this.paraPassEnabled
      && !this.passInformation // only when we haven't already scanned a pass. retaking a signature shouldn't cause a re-scan
      && this.job && this.job.jobType == JobType.Pickup
      && this.job.ePassId
      && !this.jobPerform.noShowReason
    ) {
      // this job has an e-pass assigned. we should look that up now
      return this.handlePassScan(this.job.ePassId, true);
    }
    return Promise.resolve();
  }
  async dismiss() {
    await this.modalController.dismiss();
  }

  jobTypeNameLabel() {
    return !!this.jobPerform.noShowReason
      ? this.translateService.translate('LABEL.noShowWithDash')
      : (this.jobPerform.jobType === this.JobTypeEnum.Pickup
        ? this.translateService.translate('LABEL.pickup')
        : this.translateService.translate('LABEL.dropoffNoSpace'));
  }

  signatureButtonLabel() {
    return this.translateService.translate(this.jobPerform.signature ? 'LABEL.retakeSignature' : 'LABEL.captureSignature');
  }

  getTranslatedNoShowOptionList(job: Job): NoShowOptions[] {
    return this.routeProvider.getNoShowOptionList(job)
      .map(option => ({
        ...option, displayValue: environment.features.translateNoShowOptions
        ? this.translateService.translate(NoShowReasons[option.id])
        : option.displayValue,
      }));
  }

  calculateAdditionalFare() {
    this.jobPerform.additionalFareCollected = (this.jobPerform.numberOfChildren + this.jobPerform.numberOfEscorts) * this.job.additionalRideFare;
    return this.jobPerform.additionalFareCollected;
  }

  private async showPassInformationStatus(passId: string) {
    const passInformation = this.passInformation;

    if (passInformation && passInformation.isSufficientForRide) {
      this.passHadSufficientFunds = true;
      this.jobPerform.passId = passId;
      this.isUsingPassInSomeRegard = true;

      this.jobPerform.fareCollected = 0;
    } else {
      const modal = await this.modalController.create({
        component: PassInformationPage,
        cssClass: 'parascope-medium-modal',
        componentProps: {
          passInformation: passInformation,
          job: this.job,
          showBackdrop: true,
          enableBackdropDismiss: false,
        },
      });
      modal.onDidDismiss().then(async (result) => {
        const data = result.data;
        const status: PassInformationPageResultEnum = data.status;
        switch (status) {
          case PassInformationPageResultEnum.Sufficient:
            this.passHadSufficientFunds = true;
            this.jobPerform.passId = passId;
            this.isUsingPassInSomeRegard = true;
            break;
          case PassInformationPageResultEnum.InsufficientProvide:
            this.passHadSufficientFunds = false;
            this.jobPerform.passId = passId;
            this.isUsingPassInSomeRegard = true;
            break;
          case PassInformationPageResultEnum.ServerOfflineProvide:
            this.jobPerform.passId = passId;
            this.passHadSufficientFunds = false;
            this.isUsingPassInSomeRegard = true;
            break;
          case PassInformationPageResultEnum.Deny:
            this.jobPerform.noShowReason = NoShowReasonEnum.DeniedByDriver;
            this.isUsingPassInSomeRegard = false;
            this.passInformation = null;
            await this.finishConfirm();
            break;
          case PassInformationPageResultEnum.Invalid:
            this.removePassClicked();
            break;
        }
      });

      // The "Cash Collected" input should be the fare amount until a pass is scanned.
      // When a pass is scanned, we want the input to be reduced because we're using pass instead of cash.
      if (passInformation && passInformation.remainingBalance) {
        if (passInformation.remainingBalance <= this.job.rideFare) {
          if (passInformation.remainingBalance <= 0) {
            this.jobPerform.fareCollected = 0;
          } else {
            // fare collected should be set to 0 if the pass has a negative balance
            this.jobPerform.fareCollected = this.job.rideFare - passInformation.remainingBalance;
          }
        }
      } else {
        // either we are offline or it's flex
        this.jobPerform.fareCollected = 0;
      }

      await modal.present();
    }
  }

  private async handlePassScan(passId: string, isEPass: boolean = false) {

    this.passInformationDisplayStrings = [];
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: `${this.translateService.translate('LABEL.checking')} ${isEPass ? 'ePass' : 'Pass'}...`,
    });
    await loading.present();

    try {
      const response = await this.dispatcherService.getPassInformation(passId, this.job);
      this.passInformation = response;

      // verify pass has valid ID
      if (response.assignedRiderName) {
        // assigned to specific rider
        if (response.isAssignedToRiderOnRide) {
          // pass is valid, is assigned to a rider, and that rider is the one we're picking up
          await this.showPassInformationStatus(passId);
        } else {
          // pass is valid, assigned to rider, but not the rider on this ride
          this.alertService.presentAlert({
            header: this.translateService.translate('LABEL.confirm'),
            message: `${this.translateService.translate('LABEL.passBelongs')} ${response.assignedRiderName} ${this.translateService.translate('LABEL.butPicking')} `
              + `${this.translateService.translate('LABEL.up')} ${this.job.riderFirstName} ${this.job.riderLastName}. ${this.translateService.translate('LABEL.continue')}?`,
            buttons: [{
              text: this.translateService.translate('LABEL.no'),
              handler: () => {
                this.removePassClicked();
              },
              cssClass: 'danger',
            },
            {
              text: this.translateService.translate('LABEL.yes'),
              handler: async () => {
                await this.showPassInformationStatus(passId);
              },
              cssClass: 'primary',
            },
            ],
            cssClass: 'noscroll',
            backdropDismiss: false,
          });
        }
      } else {
        // pass is valid but NOT assigned to rider
        await this.showPassInformationStatus(passId);
      }
    } catch (error) {
      // The server is not available for the pass information
      await this.showPassInformationStatus(passId);
    } finally {
      await loading.dismiss();
    }
  }

  overridePassId() {
    this.alertService.presentAlert({
      header: this.translateService.translate('LABEL.enterPassId'),
      inputs: [
        {
          type: 'text',
          name: 'passId',
        },
      ],
      buttons: [
        {
          text: this.translateService.translate('ACTION.cancel'),
          handler: data => { },
          cssClass: 'cancel',
        },
        {
          text: this.translateService.translate('ACTION.ok').toUpperCase(),
          handler: async data => {
            await this.handlePassScan(data.passId);
          },
          cssClass: 'primary',
        },
      ],
    });
  }

  scanPassClicked() {
    if (!this.deviceService.isNativeDevice) {
      this.overridePassId();
      // if we're not mobile (ie. in a sim), short circuit and return early.
      return;
    }

    this.pluginService.activateBarcodeScanner(this.translateService.translate('LABEL.placeBarcode'))
      .then(async (result) => {
        if (!result.cancelled) {
          await this.handlePassScan(result.text);
        }
      }).catch((error) => {
        alert(this.translateService.translate('LABEL.scanningFailed') + ' ' + error);
      });
  }

  removePassClicked() {
    this.passInformation = null;
    this.isUsingPassInSomeRegard = false;
    this.passHadSufficientFunds = false;
    this.jobPerform.passId = null;
  }

  private async finishConfirm() {
    try {
      console.info(
        `Calling setOdometer in JobPerformPage.finishConfirm ${this.tempOdometer}`);
      await this.locationService.setOdometer(this.tempOdometer);
      await this.routeProvider.performJob(this.jobPerform);
      await this.modalController.dismiss();
    } catch (e) {
      this.errorHandler.handleError(e);
    }
  }

  async confirmClicked() {
    if (this.clickEnabled) {
      this.clickEnabled = false;
      try {
        await this.finishConfirm();
      } finally {
        this.clickEnabled = true;
      }
    }
  }

  async captureSignature() {
    const modal = await this.modalController.create({
      component: SignaturePage,
      componentProps: {
        job: this.job,
      },
      cssClass: 'parascope-full-modal',
      backdropDismiss: false,
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    if (result?.data) {
      const data = result.data;
      this.jobPerform.signature = data.signature;
      this.jobPerform.signatureReason = data.signatureReason;

      this.backButtonVisible = false;
      this.scanEPasIfAvailable();
    } else {
      // close it.
      await this.modalController.dismiss();
    }
  }
}
