import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Injectable } from "@angular/core";
import { TrackJS } from 'trackjs';
import { environment } from "src/environments/environment";

export enum LogLevel {
  None = -1,        // Disable all logging
  Fatal = 0,        // If you have a pager, it goes off when one of these occurs.
  Warning = 1,      // Service is degraded or endangered.
  Debug = 2,        // Internal system events that aren't necessarily observable from the outside.
  Information = 3,  // The lifeblood of operational intelligence - things happen.
  Verbose = 4       // Anything and everything you might want to know about a running block of code.
}

@Injectable({ providedIn: 'root' })
export class LoggingService {

  private appName: string = environment.appName;
  private hasTrackJS = false;

  constructor(
  ) {
    if (environment.trackJS?.token) {
      this.initializeTrackJS();
      this.hasTrackJS = true;
    }

    if (environment.firebaseConfig && !Capacitor.isNativePlatform()) {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }

    this.bindConsoleCustomPrefix();
    //this should only be called once ever
  }

  private initializeTrackJS() {
    TrackJS.install({
      token: environment.trackJS.token,
      application: environment.trackJS.application,
      network: { enabled: false }, //Whether to record Telemetry events from XMLHttpRequest and fetch requests.
      console: { watch: ["log", /*"debug",*/ "info", "warn", "error"] },
      dedupe: true,
    });
  }

  public setVersion(version: string) {
    if (!this.hasTrackJS) { return; }
    TrackJS.configure({
      version: version.toString(),
    });
  }

  public async setMetaData(...data) {
    if (!this.hasTrackJS) { return; }
    data.forEach((element: { key: string, value: string }) => {
      TrackJS.addMetadata(element.key, element.value);
    });
  }

  /**
   * utility function to immediately sends a custom error to trackjs. It attempts to generate a stack trace and includes previous console messages.
   * @param data any list of objects or strings using the array expansion technique ...data: any[]
   * example: 'this is an error', {this: 'is', an: 'object'}, 'anything else you want to log'
   */
  public remoteLog(data: any) {
    if (!this.hasTrackJS) { return; }
    TrackJS.track(data);
  }

  /**
   *  adds a custom prefix to all logging messages to make them easier to find in the console
   *  example: console.log('this is a log message') will now show up as [ParaScope Log] this is a log message
   * */
  private bindConsoleCustomPrefix() {
    console.log = console.log.bind(window.console, `[${this.appName} Log]`);
    console.error = console.error.bind(window.console, `[${this.appName} Error]`);
    console.warn = console.warn.bind(window.console, `[${this.appName} Warn]`);
    console.info = console.info.bind(window.console, `[${this.appName} Info]`);
    console.debug = console.debug.bind(window.console, `[${this.appName} Debug]`);
  }

}
