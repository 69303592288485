import {Component, Input, OnInit} from '@angular/core';
import {PassDetails, PassInformationDisplayString, PassInformationPageResultEnum, isCashPass, isFlexPass} from '../../models/pass';

import {AlertService} from '../../services/alert.service';
import {DateFormatPipe} from 'ngx-moment';
import {DecimalPipe} from '@angular/common';
import {Job} from '../../models/job';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '../../services/translate.service';

@Component({
    selector: 'app-pass-information',
    templateUrl: './pass-information.page.html',
    styleUrls: ['./pass-information.page.scss'],
})
export class PassInformationPage implements OnInit {

    @Input()
    passInformation: PassDetails;

    @Input()
    job: Job;

    insufficientErrorMessage = '';
    displayStrings: PassInformationDisplayString[] = [];

    constructor(
        private modalCtrl: ModalController,
        private alertService: AlertService,
        private dateFormatPipe: DateFormatPipe,
        private numberPipe: DecimalPipe,
        private translateService:TranslateService,
    ) {}

    // Status balance expiration

    ngOnInit() {
        const passInformation = this.passInformation;
        if (!passInformation) {
            this.insufficientErrorMessage = this.translateService.translate("LABEL.cannotVerifyBalance")
                +'.';
            this.displayStrings.push({label: this.translateService.translate("LABEL.rideCost"), value: `$${this.numberPipe.transform(this.job.rideFare, '.2-2')}`});
        } else {
            if (!passInformation.passIdIsValid) {
                this.insufficientErrorMessage = this.translateService.translate("LABEL.insufficientErrorMessage").toUpperCase();
            } else if (!passInformation.isActive) {
                this.insufficientErrorMessage =  this.translateService.translate("LABEL.inactivePass").toUpperCase();
            } else if (passInformation.isExpired) {
                this.insufficientErrorMessage = this.translateService.translate("LABEL.passExpired").toUpperCase();
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.passEffective"),
                    value: `${this.formatDateString(passInformation.effectiveDate)}`,
                });
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.passExpiration"),
                    value: `${this.formatDateString(passInformation.expirationDate)}`,
                    isError: true,
                });
            } else if (passInformation.isNotYetEffective) {
                this.insufficientErrorMessage = this.translateService.translate("LABEL.passNotEffective").toUpperCase();
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.effectiveDate"),
                    value: `${this.formatDateString(passInformation.effectiveDate)}`,
                    isError: true,
                });
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.passExpiration"),
                    value: `${this.formatDateString(passInformation.expirationDate)}`,
                });
            } else if (isCashPass(passInformation.passType)) {
                // dollars insufficient
                this.insufficientErrorMessage =  this.translateService.translate("LABEL.insufficientFundsPass").toUpperCase();
                this.displayStrings.push({
                    label:  this.translateService.translate("LABEL.rideCost"),
                    value: `$${this.formatCurrency(passInformation.debitAmoutForRide)}`,
                });
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.passAmount"),
                    value: `$${this.formatCurrency(passInformation.remainingBalance)}`,
                    isError: true,
                });
            } else if (isFlexPass(passInformation.passType)) {
                // expiration dates OR num rides remaining is insufficient
                this.insufficientErrorMessage =  this.translateService.translate("LABEL.insufficientRidesPass").toUpperCase();
                this.displayStrings.push({
                    label: this.translateService.translate("LABEL.ridesRemain"),
                    value: `${this.formatDateString(passInformation.expirationDate)}`,
                });
                this.displayStrings.push({label: this.translateService.translate("LABEL.passExpiration"), value: `${passInformation.remainingRides}`, isError: true});
            }
        }
    }

    formatDateString(date: Date) {
        return this.dateFormatPipe.transform(date, 'MM/DD/YYYY');
    }

    formatCurrency(val: number) {
        return this.numberPipe.transform(val, '.2-2');
    }

    async statusOkClicked() {
        const data = {
            status: PassInformationPageResultEnum.Sufficient,
        };
        await this.modalCtrl.dismiss(data);
    }

    async provideRideClicked() {
        let data = {
            status: PassInformationPageResultEnum.InsufficientProvide,
        };
        if (typeof this.passInformation === 'undefined') {
            data = {
                status: PassInformationPageResultEnum.ServerOfflineProvide,
            };
        } else if (!this.passInformation.passIdIsValid) {
            data = {
                status: PassInformationPageResultEnum.Invalid,
            };
        }
        await this.modalCtrl.dismiss(data);
    }

    denyRideClicked() {
        this.alertService.presentAlert({
            header: this.translateService.translate("ACTION.confirm"),
            message: `${this.translateService.translate("ACTION.confirmDenyRide")}`,
            buttons: [
                {
                    text: 'No',
                    handler: () => {
                        // Do nothing
                    },
                    cssClass: 'primary',
                },
                {
                    text: 'Yes',
                    handler: async () => {
                        const data = {
                            status: PassInformationPageResultEnum.Deny,
                        };
                        await this.modalCtrl.dismiss(data);
                    },
                    cssClass: 'danger',
                },
            ],
            backdropDismiss: false,
        });
    }
}
