import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Job, JobType, buildJobPerform, buildOnTheWay, canArrive, canOnTheWay } from '../../models/job';
import { ModalController, Platform } from '@ionic/angular';

import { AlertService } from '../../services/alert.service';
import { AppData } from 'src/app/data';
import { DeviceService } from '../../services/device.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { JobDetailsPage } from '../../pages/job-details/job-details.page';
import { JobPerformPage } from '../../pages/job-perform/job-perform.page';
import { LocationService } from '../../services/location.service';
import { NoShowReasonEnum } from '../../models/no-show-option';
import { NotificationService } from '../../services/notification.service';
import { RouteProviderService } from '../../services/route-provider.service';
import { Router } from '@angular/router';
import { ScheduledEvent } from '../job-list/job-list.component';
import { TranslateService } from '../../services/translate.service';
import { UtilityService } from 'src/app/services/utility.service';
import { buildCallRequest } from 'src/app/models/call-request';
import { environment } from 'src/environments/environment';

const EnableGoogleMapsApiCall = false;

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent {

  public readonly data = inject(AppData);

  @Input() job: Job;
  @Input() isDetailView: boolean;
  @Output() closeParent = new EventEmitter<PerformJobEventArgs>();
  isIos = this.platform.is('ios');
  public readonly showNotify = environment.features.notifyPassenger;

  constructor(
    public router: Router,
    private routeProvider: RouteProviderService,
    public deviceService: DeviceService,
    private dispatcherService: DispatcherService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private alertService: AlertService,
    private translateService: TranslateService,
    private platform: Platform,
    private utility: UtilityService,
  ) {
  }

  //  Buttons on the card need to have a stopPropagation because the card itself is clickable
  //  Therefore when you click the button it also tries to open the card details if you don't add
  //  the event.stopPropagation()

  get onTheWayCompleted(): boolean {
    if (environment.features.onTheWayButton && this.data.route.allowOnTheWay && this.job.jobType === JobType.Pickup) {
      // if there is a value in onTheWayTime, it's completed
      return !!this.job.onTheWayTime;
    }
    // if OTW is disabled, then we skip this check.
    return true;
  }

  async onTheWay(event: Event) {
    event.stopPropagation();
    const estimatedTimeOfArrival: Date = this.job.location.coordinate && EnableGoogleMapsApiCall
    ? await this.locationService.calculateArrivalTime(
        this.job.location.coordinate.latitude,
        this.job.location.coordinate.longitude,
    )
    : null;

    const gpsReport = this.data.gpsReport;
    const onTheWay = buildOnTheWay({ job: this.job, estimatedTimeOfArrival, gpsReport });
    const onTheWays = [onTheWay];

    const { notifyAdditionalJobs } = environment.features;

    const similarJobs = this.routeProvider.getSimilarJobs({ can: canOnTheWay, job: this.job });
    const additionalOnTheWays = similarJobs.map(otherJob => buildOnTheWay({ job: otherJob, estimatedTimeOfArrival, gpsReport}));
    const message = similarJobs.reduce((acc, otherJob) => {
      acc += `<br>${this.translateService.translate('LABEL.pickupFor')} ${otherJob.riderFirstName} ${otherJob.riderLastName}`;
      return acc;
    }, '');

    if (additionalOnTheWays.length > 0 && notifyAdditionalJobs) {
      this.alertService.presentAlert({
        cssClass: 'alert-wrapperz',
        header: this.translateService.translate('LABEL.reportJobsOTW'),
        message,
        buttons: [{
          text: this.translateService.translate('LABEL.yes'),
          cssClass: 'primary',
          handler: () => onTheWays.push.apply(onTheWays, additionalOnTheWays),
        }, {
          text: this.translateService.translate('LABEL.no'),
          cssClass: 'danger',
          handler: () => {},
        }],
      });
    } else if (additionalOnTheWays.length > 0) {
      onTheWays.push.apply(onTheWays, additionalOnTheWays);
    }
    await this.routeProvider.reportJobsOnTheWay(onTheWays);
    if (this.isDetailView) { this.requestDetailViewClose({ jobPerformed: true, rideId: this.job.rideId }); }
  }

  async arriveJob(event: Event) {
    event.stopPropagation();

    const hasArrived = this.locationService.hasArrived(this.job.location.coordinate);

    if (hasArrived) { await this.finalizeArrive(); return; }

    this.alertService.presentAlert({
      cssClass: 'alert-wrapperz',
      header: this.translateService.translate('LABEL.confirmArrival'),
      message: this.translateService.translate('LABEL.jobNotInRange')
        + `${this.job.jobType === JobType.Pickup ? this.translateService.translate('LABEL.pickup') + ' ' : this.translateService.translate('ACTION.dropOff') + ' '} ${this.translateService.translate('LABEL.for')} `
        + `${this.job.riderFirstName}  ${this.job.riderLastName}?`,
      buttons: [{
        text: this.translateService.translate('LABEL.yes'),
        cssClass: 'primary',
        handler: async () => { await this.finalizeArrive(); },
      }, {
        text: this.translateService.translate('LABEL.no'),
        cssClass: 'danger',
        handler: () => { },
      }],
    });
  }

  async finalizeArrive() {
    const jobsToArrive = [ this.job ];

    const { notifyAdditionalJobs } = environment.features;

    const similarJobs: ScheduledEvent<Job>[] = this.routeProvider.getSimilarJobs({ can: canArrive, job: this.job });
    const message = similarJobs.reduce((acc, otherJob) => {
      acc += `<br>${otherJob.jobType === JobType.Pickup ? this.translateService.translate('LABEL.pickup') : this.translateService.translate('ACTION.dropOff')}`
      + ` ${this.translateService.translate('LABEL.for')} ${otherJob.riderFirstName} ${otherJob.riderLastName}`;
      return acc;
    }, '');

    if (similarJobs.length > 0 && notifyAdditionalJobs) {
      this.alertService.presentAlert({
        cssClass: 'alert-wrapperz',
        header: this.translateService.translate('LABEL.arriveJobs'),
        message,
        buttons: [{
          text: this.translateService.translate('LABEL.yes'),
          cssClass: 'primary',
          handler: () => jobsToArrive.push.apply(jobsToArrive, similarJobs),
        }, {
          text: this.translateService.translate('LABEL.no'),
          cssClass: 'danger',
          handler: () => {},
        }],
      });
    } else if (similarJobs.length > 0) {
      jobsToArrive.push.apply(jobsToArrive, similarJobs);
    }

    await this.dispatcherService.arriveJobs(jobsToArrive);
    if (this.isDetailView) { this.requestDetailViewClose({ jobPerformed: true, rideId: this.job.rideId }); }
  }

  public notifyPassenger(event: Event) {
    event.stopPropagation();
    this.alertService.presentAlert({
      header: this.translateService.translate('TITLE.passengerNotify'),
      message: `${this.translateService.translate('LABEL.reqDispatchCall')} ${this.job.riderFirstName} ${this.job.riderLastName}?`,
      buttons: [{
        text: this.translateService.translate('LABEL.yes'),
        cssClass: 'primary',
        handler: async () => {
          const request = buildCallRequest({ job: this.job, gpsReport: this.data.gpsReport });
          await this.dispatcherService.callRequest(request);
          this.notificationService.success(this.translateService.translate('LABEL.reqSent'));
        },
      }, {
        text: this.translateService.translate('LABEL.no'),
        cssClass: 'secondary',
        handler: () => {},
      }],
    });
  }

  async showJobDetails(event: Event) {
    event.stopPropagation();
    if (this.isDetailView) { return; }

    const detailModal = await this.modalController.create({
      cssClass: 'parascope-full-modal',
      component: JobDetailsPage,
      backdropDismiss: false,
      componentProps: {
        'job': this.job,
      },
    });
    await detailModal.present();
    const returnAction = await detailModal.onDidDismiss();

    // this should only happen if it's a crappy tab.
    if (returnAction.data && returnAction.data.hasOwnProperty('signature')) {
      await this.performJob(returnAction.data.signature, returnAction.data.signatureReason, returnAction.data.noShowReason);
    }
  }

  public navigateToDestination(event: Event) {
    event.stopPropagation();
    if (!this.job.location.coordinate) {
      // there is no location set, so notify the user
      this.notificationService.warning(this.translateService.translate('LABEL.cannotNavigate'));
      return;
    }
    this.locationService.navigateToDestination(this.job.location.coordinate);
  }

  public previewLocation(event: Event) {
    event.stopPropagation();
    if (!this.job.location.coordinate) {
      // there is no location set, so notify the user
      this.notificationService.warning(this.translateService.translate('LABEL.cannotOpenNoGeo'));
      return;
    }
    this.locationService.previewLocation(this.job.location.coordinate, this.job.location.name);
  }

  public async navigateToNoShowPerform(event: Event) {
    event.stopPropagation();
    // default reason to no client and they can change on next screen
    await this.performJob(null, null, NoShowReasonEnum.NoClient);
  }

  public async navigateToDropoffPerform(event: Event) {
    event.stopPropagation();
    await this.performJob(null, null);
  }

  public async navigateToPickupPerform(event: Event) {
    event.stopPropagation();
    await this.performJob(null, null);
  }

  private async performJob(signature: string, signatureReason: string, noShowReason?: NoShowReasonEnum) {
    if (this.isDetailView && this.deviceService.isPOSAndroidTab) {
      this.requestDetailViewClose({
        signature: signature,
        signatureReason: signatureReason,
        noShowReason: noShowReason,
      } as PerformJobEventArgs);
      return;
    }

    const performModal = await this.modalController.create({
      component: JobPerformPage,
      backdropDismiss: false,
      componentProps: {
        job: this.job,
        jobPerform: buildJobPerform({ job: this.job, signature, signatureReason, noShowReason }),
      },
      cssClass: 'parascope-full-modal',
    });
    await performModal.present();
    await performModal.onDidDismiss();
    if (this.isDetailView) { this.requestDetailViewClose({ jobPerformed: true, rideId: this.job.rideId }); }
  }

  public handleName(first: string, last: string): string {
    return this.utility.handleName(first, last);
  }

  private requestDetailViewClose(data) {
    this.closeParent.emit(data);
  }


}

export class PerformJobEventArgs {
  signature: string;
  signatureReason: string;
  noShowReason?: NoShowReasonEnum;
}
