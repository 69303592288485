import {Component, Input} from '@angular/core';

import { ScheduledEvent } from '../job-list/job-list.component';

@Component({
    selector: 'app-job-or-nonservice-period',
    templateUrl: './job-or-nonservice-period.component.html',
    styleUrls: ['./job-or-nonservice-period.component.scss'],
})
export class JobOrNonservicePeriodComponent {
  @Input() jobOrNonServicePeriod: ScheduledEvent;
}
