//Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'com.cts.ParaScope',
  ionicAppId: '6b9ec9dc',
  ionicAppChannel: 'configurable',
  publicVersion: '7.0.0',
  baselineBuildId: 8610226,
  date: '2024-07-10 16:25:06.367Z',
  internalVersion: '24.192.985',
};
