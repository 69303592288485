<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="outline" size="default" color="secondary" (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'TITLE.gasReport' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-card>
    <ion-card-content>
      <div class="field-container">
        <span class="label">{{ 'LABEL.odometer' | translate }}</span>
        <div class="increment" incrementControl [maxValue]="1000000" [(ngModel)]="amounts[AmountsEnum.Odometer]"></div>
      </div>

      <div class="field-container">
        <span class="label">{{ 'LABEL.gasGallons' | translate }}</span>
        <div class="increment" incrementControl [allowNull]="true" [maxValue]="1000" [(ngModel)]="amounts[AmountsEnum.GasGallons]"></div>
      </div>

      <div class="field-container">
        <span class="label">{{ 'LABEL.gasCost' | translate }}</span>
        <div class="increment" incrementControl [allowNull]="true" [maxValue]="10000" [(ngModel)]="amounts[AmountsEnum.GasCost]"></div>
      </div>
    </ion-card-content>
  </ion-card>

</ion-content>

<ion-footer>
  <ion-button color="primary" expand="block" (click)="submitGasReportClicked()">
    {{ 'ACTION.submit' | translate }}
  </ion-button>
</ion-footer>
