import { Component, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DeviceService } from '../../services/device.service';
import { LocationService } from '../../services/location.service';
import { VersionService } from '../../services/version.service';
import { LoggingService } from '../../services/logging.service';
import { AlertService } from '../../services/alert.service';
import { SettingsService } from '../../services/settings.service';
import { MapTypeEnum, NativePluginService } from 'src/app/services/plugin/native-plugins.service';
import { buildInfo } from 'src/config/build-info';

import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { environment } from '../../../environments/environment';
import { Pro_SnapshotInfo } from '../../services/plugin/snapshot-management.service';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { AppData } from 'src/app/data';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.page.html',
  styleUrls: ['./debug-info.page.scss'],
})
export class DebugInfoPage implements OnInit, OnDestroy {

  private readonly data = inject(AppData);

  bring2frontPermission = undefined;

  buildInfo = buildInfo;
  appName: string = environment.appName;
  snapshotInfo: Pro_SnapshotInfo;
  offlineMode = this.data.offlineMode;
  public deviceProperties = [];
  destroyed$ = new Subject<boolean>();

  constructor(
    public route: Router,
    private modalController: ModalController,
    public deviceService: DeviceService,
    private versionService: VersionService,
    public locationService: LocationService,
    public loggingService: LoggingService,
    public settingsService: SettingsService,
    private alertService: AlertService,
    private pluginService: NativePluginService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.deviceService.getDeviceInfoArray().then(deviceProperties => this.deviceProperties = deviceProperties);
    this.pluginService.checkPermissionsForMoveToForeground()
      .then(x => this.zone.run(() => {
      this.bring2frontPermission = x;
      }));

    this.versionService.snapshotInfo$.pipe(
      takeUntil(this.destroyed$),
      tap(value => console.debug(value)),
      tap(value => this.snapshotInfo = value),
      catchError(err => of(null)),
    ).subscribe();

  }
  async dismiss() {
    await this.modalController.dismiss();
  }

  async throwError() {
    throw new Error('A test error has been thrown and not caught');
  }

  async unhandledError() {

    const crash = async () => {
      await FirebaseCrashlytics.crash({ message: 'Test' });
    };
  }

  async tryCatchError() {

    try {
      (<any>this).aMethodThatDoesNotExist();
    } catch (e) {
      console.error('A test fatal error has occurred', e);
    }
  }

  async toggleDebug() {
    await this.locationService.toggleDebug();
  }

  async setBackgroundMode() {
    await this.deviceService.setBackgroundMode();
  }

  get currentDispatcherUrl(): string {
    return this.data.localSettings.dispatcherUrl;
  }

  toggleOfflineMode() {
    const connectionStatus = this.data.connectionStatus;
    this.data.set('connectionStatus', { ...connectionStatus, connected: this.offlineMode });
  }

  createError() {
    this.alertService.presentAlert({
      header: 'What type of Error?',
      message: `This is a way to test error handling of ${environment.appName}`,
      buttons: [
        {
          text: 'Thrown',
          handler: () => {
            throw new Error('A test error has been thrown and not caught');
          },
          cssClass: 'primary',
        },
        {
          text: 'Unhandled',
          handler: async () => {
            //await (<any>this).aMethodThatDoesNotExist();

            await FirebaseCrashlytics.crash({ message: 'Test' });
          },
          cssClass: 'secondary',
        },
        {
          text: 'Caught',
          handler: () => {
            try {
              (<any>this).aMethodThatDoesNotExist();
            } catch (e) {
              console.error('A test fatal error has occurred', e);
            }
          },
          cssClass: 'tertiary',
        },
      ],
    });
  }

  async testBring2Front(): Promise<void> {
    await this.pluginService.launchMappingApplication(35.961707, -83.9233535, MapTypeEnum.TurnByTurnDirections);
    setTimeout(async () => {
      this.zone.run(async () => {
        await this.pluginService.tryWakeUpAndBringToFront();
        await this.pluginService.moveToForegroundUsingCapacitorPlugin();
        alert('You have arrived!');
      });
    }, 1000 * 10);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
