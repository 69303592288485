<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        *ngIf="data.currentRouteIsPreview"
        color="secondary"
        fill="outline"
        (click)="dismissModal()"
      >
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
      <ion-button
        fill="solid"
        color="secondary"
        *ngIf="!data.currentRouteIsPreview"
        (click)="adjustOdometerClicked()"
      >
        <ion-icon slot="start" name="speedometer"></ion-icon>
        <span>{{ odometer$ | async }}</span>
      </ion-button>
    </ion-buttons>

    <ion-title *ngIf="!data.currentRouteIsPreview">
      {{ clock | async | amDateFormat:'HH:mm' }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button
        fill="solid"
        color="primary"
        *ngIf="!data.currentRouteIsPreview && data.route.flagStopsEnabled"
        (click)="performFlagStopClicked()"
      >
        <ion-icon name="flag" slot="start"></ion-icon>
        <span class="hidden-md">{{ 'LABEL.performFlagStop' | translate }}</span>
        <span class="show-md">{{ 'LABEL.flagStop' | translate }}</span>
      </ion-button>

      <ion-button
        color="primary"
        fill="solid"
        *ngIf="!data.currentRouteIsPreview"
        (click)="toggleMenu()"
        [attr.aria-label]="'ACTION.menu' | translate"
      >
        <span class="hidden-md">{{ 'ACTION.menu' | translate }}&nbsp;</span>
        <ion-icon name="menu"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="home-content">

  <app-job-list />

  <ng-container *ngIf="!data.currentRouteIsPreview && !data.route.jobs?.length">
    <ion-row>
      <ion-col size="12">
        <ion-button
          color="primary"
          fill="outline"
          expand="block"
          [disabled]="!data.route.endDepoLocation.coordinate"
          *ngIf="data.route.endDepoLocation"
          (click)="navigateHomeClicked()"
        >
          <ion-icon name="home" slot="start"></ion-icon>
          {{ 'ACTION.navigateToEndDepot' | translate }}
        </ion-button>
      </ion-col>

      <ion-col size="12">
        <ion-button
          color="secondary"
          expand="block"
          *ngIf="routeProvider.canCompleteRoute"
          (click)="completeRouteClicked()"
          class="complete"
        >
          <ion-icon name="checkmark-circle" slot="start"></ion-icon>
          {{ 'ACTION.completeRoute' | translate }}
        </ion-button>

      </ion-col>
    </ion-row>
  </ng-container>
</ion-content>
