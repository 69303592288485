import { } from 'moment';

import { CalendarPipe, DateFormatPipe, MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdjustOdometerPage } from '../pages/adjust-odometer/adjust-odometer.page';
import { CommonModule } from '@angular/common';
import { DebugInfoPage } from '../pages/debug-info/debug-info.page';
import { DisclaimerPage } from '../pages/disclaimer/disclaimer.page';
import { EnvironmentPickerComponent } from '../components/environment-picker/environment-picker.component';
import { FlagStopPassScanPage } from '../pages/flag-stop-pass-scan/flag-stop-pass-scan.page';
import { FlagStopPerformPage } from '../pages/flag-stop-perform/flag-stop-perform.page';
import { GpsInfoPage } from '../pages/gps-info/gps-info.page';
import { GroupArriveDialog } from '../components/grouped-stops/group-arrive/group-arrive.dialog';
import { GroupDetailsDialog } from '../components/grouped-stops/group-details/group-details.dialog';
import { GroupNotifyDialog } from '../components/grouped-stops/group-notify/group-notify.dialog';
import { GroupOnTheWayDialog } from '../components/grouped-stops/group-on-the-way/group-on-the-way.dialog';
import { GroupPerformDialog } from '../components/grouped-stops/group-perform/group-perform.dialog';
import { GroupedStopsComponent } from '../components/grouped-stops/grouped-stops.component';
import { IncrementalControlComponent } from '../components/increment-control/increment-control.component';
import { InspectionPage } from '../pages/inspection/inspection.page';
import { IonicModule } from '@ionic/angular';
import { JobCardGeneralComponent } from '../components/job-card-general/job-card-general';
import { JobComponent } from '../components/job/job.component';
import { JobDetailsPage } from '../pages/job-details/job-details.page';
import { JobListComponent } from '../components/job-list/job-list.component';
import { JobOrNonservicePeriodComponent } from '../components/job-or-nonservice-period/job-or-nonservice-period.component';
import { JobPerformPage } from '../pages/job-perform/job-perform.page';
import { MaintenancePage } from '../pages/maintenance/maintenance.page';
import { MessageDialogComponent } from '../pages/messages/message-dialog/message-dialog.component';
import { NgModule } from '@angular/core';
import { NonServicePeriodComponent } from '../components/non-service-period/non-service-period.component';
import { OdometerPage } from '../pages/odometer/odometer.page';
import { PassEntryPage } from '../pages/pass-entry/pass-entry.page';
import { PassInformationPage } from '../pages/pass-information/pass-information.page';
import { PermissionsComponent } from '../components/permissions/permissions.component';
import { ScheduledFlagStopComponent } from '../components/scheduled-flag-stop/scheduled-flag-stop.component';
import { SignalIndicatorComponent } from '../components/signal-indicator/signal-indicator.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePage } from '../pages/signature/signature.page';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateToggleComponent } from '../components/translate-toggle/translate-toggle.component';
import { ZendriveTestingPage } from '../pages/zendrive-testing/zendrive-testing.page';

@NgModule({
  declarations: [
    AdjustOdometerPage,
    DebugInfoPage,
    DisclaimerPage,
    EnvironmentPickerComponent,
    FlagStopPassScanPage,
    FlagStopPerformPage,
    GpsInfoPage,
    GroupArriveDialog,
    GroupDetailsDialog,
    GroupedStopsComponent,
    GroupedStopsComponent,
    GroupNotifyDialog,
    GroupOnTheWayDialog,
    GroupPerformDialog,
    IncrementalControlComponent,
    InspectionPage,
    JobCardGeneralComponent,
    JobComponent,
    JobDetailsPage,
    JobListComponent,
    JobOrNonservicePeriodComponent,
    JobPerformPage,
    MaintenancePage,
    MessageDialogComponent,
    NonServicePeriodComponent,
    OdometerPage,
    PassEntryPage,
    PassInformationPage,
    PermissionsComponent,
    ScheduledFlagStopComponent,
    SignalIndicatorComponent,
    SignaturePage,
    TranslateToggleComponent,
    ZendriveTestingPage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MomentModule,
    ReactiveFormsModule,
    SignaturePadModule,
    TranslateModule,
  ],
  providers: [
    CalendarPipe,
    DateFormatPipe,
  ],
  exports: [
    AdjustOdometerPage,
    CalendarPipe,
    DateFormatPipe,
    DebugInfoPage,
    EnvironmentPickerComponent,
    FlagStopPassScanPage,
    FlagStopPerformPage,
    GpsInfoPage,
    GroupArriveDialog,
    GroupDetailsDialog,
    GroupedStopsComponent,
    GroupNotifyDialog,
    GroupOnTheWayDialog,
    GroupPerformDialog,
    IncrementalControlComponent,
    InspectionPage,
    JobCardGeneralComponent,
    JobComponent,
    JobListComponent,
    JobOrNonservicePeriodComponent,
    MaintenancePage,
    MessageDialogComponent,
    NonServicePeriodComponent,
    OdometerPage,
    PassInformationPage,
    PermissionsComponent,
    ReactiveFormsModule,
    ScheduledFlagStopComponent,
    SignalIndicatorComponent,
    TranslateModule,
    TranslateToggleComponent,
  ],
})
export class SharedModule {
}
