import { Component, OnInit, inject } from '@angular/core';

import { AppData } from 'src/app/data';
import { LocationService } from '../../services/location.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-adjust-odometer',
  templateUrl: './adjust-odometer.page.html',
  styleUrls: ['./adjust-odometer.page.scss'],
})
export class AdjustOdometerPage implements OnInit {

  private readonly modalController = inject(ModalController);
  private readonly locationService = inject(LocationService);

  public readonly data = inject(AppData);

  suggestedOdometer: number;
  confirmedOdometer: number;

  async ngOnInit() {
    this.suggestedOdometer = this.confirmedOdometer = this.data.gpsReport?.odometer || 0;
  }

  async submitClicked() {
    await this.locationService.setOdometer(this.confirmedOdometer);
    await this.modalController.dismiss();
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }
}
