import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService as ngxTranslate } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {

  private readonly SUPPORTED_LANGUAGES: Language[] = ['en', 'es'];
  private readonly KEY = 'parascope_lang';
  public readonly currentLanguage$ = new ReplaySubject<'en' | 'es'>(1);

  /*
   * Please keep in alphabetical order to
   * - make it easier to find properties
   * - reduce merge conflicts
  */
  private readonly testEn = {
    "ACTION": {
      "accept": "Accept",
      "acknowledge": "acknowledge",
      "allow": "Allow",
      "answer": "Answer",
      "applicationValidated": "Application Validated!",
      "arrive": "Arrive",
      "back": "Back",
      "cancel": "Cancel",
      "cancelAccount": "Cancel Driver Account",
      "captureAtDropOff": "Capture at DropOff",
      "changeTo": "Change to",
      "checkUpdates": "Check For Updates",
      "clearSignature": "Clear Signature",
      "close": "Close",
      "completeRoute": "Complete Route",
      "configureProvider": "Configure Provider",
      "confirm": "Confirm",
      "confirmDenyRide": "Are you sure you want to Deny Ride?",
      "decrement": "Decrement",
      "denyRide": "Deny Ride",
      "deviceInfo": "Device Information",
      "dismiss": "Dismiss",
      "done": "Done",
      "dropOff": "DropOff",
      "fairmatic": "Fairmatic",
      "finish": "Finish",
      "gasReport": "Gas Report",
      "go": "Go",
      "increment": "Increment",
      "login": "Log In",
      "logout": "Log Out",
      "menu": "Menu",
      "navigate": "Navigate",
      "navigateToEndDepot": "Navigate To End Depot",
      "noShow": "No Show",
      "notify": "Notify",
      "ok": "Ok",
      "okay": "Okay",
      "onboard": "On Board",
      "onTheWay": "On The Way",
      "preview": "Preview",
      "provideRide": "Provide Ride",
      "quickPerform": "Quick Perform",
      "refresh": "Refresh",
      "register": "New Driver Registration",
      "removePass": "Remove Pass",
      "resume": "Resume",
      "save": "Save",
      "scanPass": "Scan Pass",
      "sending": "Sending",
      "sendMessage": "Send Message",
      "serviceCritical": "Service Critical",
      "serviceCriticalCritical": "Critical",
      "serviceDueSoon": "Service Due Soon",
      "serviceDueSoonDue": "Due",
      "start": "Start",
      "submit": "Submit",
      "testGPS": "Test GPS",
      "toggle": "Toggle",
      "update": "Update",
      "viewConsoleLog": "View Console Log",
    },
    "ALT_TEXT": {
      "parascope": environment.appName + " for " + environment.companyName,
    },
    "LABEL": {
      "acceptedPassHas": "has been accepted. The pass has",
      "account": "Account",
      "accountCanceled": "Your account has been marked for deletion within the next 5 days.",
      "acknowledged": "Acknowledged",
      "added": "added",
      "additionalPassengerFare": "Additional Passenger Fare",
      "addrChangedFrom": "address has changed from ",
      "advLogging": "Advanced Logging",
      "ambulatorySeats": "Ambulatory Seats",
      "areYouSureCancelAccount": "Are you sure you want to cancel your driver account?",
      "arrivedAtDestination": "You have arrived at your destination",
      "arriveJobs": "Do you also want to arrive these jobs?",
      "assignedRider": "Assigned Rider",
      "at": "at",
      "atDropoff": "at Dropoff",
      "auth": "Auth",
      "balanceRemaining": "Balance Remaining",
      "boardedHere": "Boarded",
      "break": "Break",
      "butPicking": "but you are picking",
      "callHistory": "Call History",
      "callHistoryNoCalls": "There have been no fairmatic calls in this session.",
      "cancelAccount": "Cancel Driver Account",
      "cancelAccountNotLoggedIn": "Please login before cancelling your account.",
      "cannotNavigate": "Cannot navigate to location because it has not been geolocated",
      "cannotOpenNoGeo": "Cannot open the location on a map because it has not been geolocated",
      "cannotVerifyBalance": "Cannot verify pass balance. Please collect cash or pass will be debited when connection is available",
      "captureSignature": "Capture Signature",
      "cashCollected": "Cash Collected",
      "changeMessages": "Change Messages",
      "checking": "Checking",
      "checkingPass": "Checking pass...",
      "children": "Children",
      "close": "Close",
      "completeStop": "Complete Stop",
      "confirm": "Confirm",
      "confirmArrival": "Confirm Arrival",
      "confirmDenyRide": "Are you sure you want to deny this ride?",
      "confirmRiderSign": "Confirm Rider Signature",
      "confirmYouAt": "Please confirm you are at",
      "continue": "Continue",
      "criticalInspFailed": "Critical inspections failed - please contact your dispatcher",
      "currentBalance": "Current Balance",
      "currentlyOnboard": "On Board",
      "currentRidesRemain": "Current number of rides remaining",
      "customer": "Provider",
      "days": "days",
      "debitRideCount": "Debit Ride Count",
      "deboard": "Deboard",
      "deBoardedHere": "Deboarded",
      "deboardPass": "Deboard Pass",
      "description": "Description",
      "deviceDescription": "Device Description",
      "deviceInfo": "Device Info",
      "disable": "Disable",
      "doesNotExpire": "Does Not Expire",
      "doNotHavePermission": "If you do not have permission to access the",
      "driverIdNumber": "Driver ID",
      "driverRegistered": "Registered Successfully!",
      "driverRegistrationWait": "If you'd like to be considered to become a Driver, please submit your information below. " + environment.companyName + " will be in touch shortly",
      "dropOff": "Drop off",
      "dropoffNoSpace": "Dropoff",
      "dropOffs": "Drop offs",
      "effectiveDate": "Effective Date",
      "emailAddress": "Email Address",
      "enterPassId": "Please enter pass ID",
      "escorts": "Escorts",
      "expiresOn": "Expires on",
      "fare": "Fare",
      "fareCollected": "Fare Collected",
      "fareOwed": "Fare Owed",
      "fareType": "Fare Type",
      "finishingInsp": "Finishing Inspection...",
      "firstName": "First Name",
      "flagStop": "Flag Stop",
      "flagStopsOnBoard": "Total Flag Stop Passengers On Board",
      "for": "For",
      "gasCost": "Gas - Cost",
      "gasGallons": "Gas - Gallons",
      "gasReportSent": "Gas report sent",
      "groupNotInRange": "Group location is not within range. Are you sure you want to arrive this group?",
      "hasChangedFrom": "has changed from",
      "help": "Help",
      "inactivePass": "PASS IS INACTIVE",
      "inspection": "Inspection",
      "insufficientErrorMessage": "THE PASS ID IS INVALID",
      "insufficientFundsPass": "INSUFFICIENT FUNDS ON PASS",
      "insufficientRidesPass": "INSUFFICIENT RIDES ON PASS",
      "invalidPIN": environment.features.requireVehicleNumber ? "Wrong login name or password" : "Invalid PIN",
      "jobNotInRange": "Job location is not within range. Are you sure you want to arrive this ",
      "language": "Language",
      "lastName": "Last Name",
      "loadingMore": "Loading More Data...",
      "loadMore": "Load More Records",
      "loggingIn": "Logging In",
      "map": "Map",
      "messageNotSent": "Message not sent, please select an option",
      "messageSent": "Message sent",
      "navigate": "Navigate",
      "no": "No",
      "noBeginning": "No Beginning",
      "noDeboard": "No deboard has been recorded",
      "noDeboardPass": "pass has been deboarded",
      "noExpiration": "No Expiration",
      "noMessages": "No Messages",
      "noRidersTypeOf": "There; are; no; riders; onboard; with the rider; type of",
      "noShow": "No Show",
      "noShowWithDash": "No-Show",
      "notAssigned": "Not Assigned",
      "notify": "Notify",
      "notifyAdmin": "Store, please notify your administrator",
      "notReady": "Not Ready",
      "numOfChildren": "Number of Children",
      "numOfEscorts": "Number of Escorts",
      "numRidesRemain": "Number of Rides Remaining",
      "odometer": "Odometer",
      "onboard": "Onboard",
      "onboardPass": "Onboard Pass",
      "parentGuardianSig": "Parent / Guardian Signed",
      "pass": "Pass",
      "passAcceptedBalance": "has been accepted. The pass has a balance of",
      "passAmount": "Pass Amount",
      "passBalance": "Pass Balance",
      "passBelongs": "Pass belongs to",
      "passDebited": "Pass will be Debited",
      "passDebitedFor": "Pass will be Debited for",
      "passEffective": "Pass Effective",
      "passEffectiveDate": "Pass Effective Date",
      "passengersOnBoard": "Passengers On Board",
      "passExpiration": "Pass Expiration",
      "passExpired": "PASS EXPIRED",
      "passless": "Passless",
      "passlessBoardingDeboarding": "Passless boarding and deboarding",
      "passNotEffective": "PASS IS NOT EFFECTIVE YET",
      "performFlagStop": "Perform Flag Stop",
      "performGroupedStop": "Perform Grouped Stop",
      "performScheduledStop": "Perform Scheduled Stop",
      "pickup": "Pickup",
      "pickupAndDropoff": "Pickup and Dropoff",
      "pickupFor": "Pickup For",
      "pickups": "Pickups",
      "placeBarcode": "Place a barcode inside the scan area",
      "pleaseCollect": "Please Collect",
      "postTrip": "Post-Trip",
      "preTrip": "Pre-Trip",
      "previewRoute": "Previewing Route...",
      "priority": "Priority",
      "providerName": "Provider Name",
      "pullIn": "Pull In",
      "pullOut": "Pull Out",
      "rapidOnboardBluetooth": "Rapid Onboard From Bluetooth",
      "rapidOnboardTablet": "Rapid Onboard From Tablet",
      "ready": "Ready",
      "refreshingRoute": "Refreshing Route...",
      "remainBalanceAfterDebit": "Remaining Pass Balance after debit",
      "remaining": "remaining",
      "remainingRides": "Remaining Rides",
      "remainRidesAfterDebit": "Remaining Rides Balance after debit",
      "removed": "removed",
      "reportJobsOTW": "Do you also want to report these jobs as on the way?",
      "reqDispatchCall": "Request that dispatch call",
      "reqSent": "Request sent",
      "reqSentFor": "Request sent for",
      "reservationSpecialDir": "Reservation Special Directions",
      "retakeSignature": "Retake Signature",
      "ride": "Ride",
      "rideCost": "Ride Cost",
      "riderFareAccepted": "Rider fare has been accepted",
      "riderImage": "Rider Image",
      "riderId": "Customer ID",
      "riderName": "Rider Name",
      "riderPhone": "Rider Phone",
      "riderSigReceived": "Rider Signature Received",
      "riderSpecDir": "Rider Special Directions",
      "riderSpecialDir": "Rider Special Directions",
      "riderUnableSig": "Rider Unable to Sign – Facility Signed",
      "rides": "Rides",
      "ridesRemain": "Rides Remaining",
      "ridesRemainAfter": "rides remaining after this one and",
      "route": "Route",
      "runningInBackground": "Running in background",
      "scannedPassNotFound": "The scanned pass cannot be found on board. Please use the deboard button on the type of rider you are deboarding",
      "scanningFailed": "Scanning Failed",
      "scanningField": "Scanning Field",
      "scheduled": "scheduled",
      "scheduledStop": "Scheduled Stop",
      "scheduledTime": "Scheduled Time",
      "seeOn": "See On",
      "selectMessage": "Select Message",
      "signature": "Signature",
      "sigPerformDropOff": "Signature will be performed at dropoff",
      "snapshot": "Snapshot",
      "startingRoute": "Starting Route...",
      "stop": "stop",
      "success": "Success",
      "tapForDetails": "Tap for details",
      "timePoint": "Time Point",
      "to": "to",
      "today": "today",
      "total": "Total",
      "totalPassengersOnBoard": "Total Passengers On Board",
      "units": "Units",
      "unlimited": "Unlimited",
      "unscheduled": "unscheduled",
      "up": "up",
      "validateApp": "Validate Application",
      "vehicle": "Vehicle",
      "vehicleInspChecklist": "Vehicle Inspection Checklist",
      "vehicleNumber": "Vehicle ID",
      "viewIn": "View In",
      "waitWhileRouteValidating": "Please wait while your route is validated.",
      "wheelchairStations": "Wheelchair Stations",
      "willCall": "Will Call",
      "yes": "Yes",
      "youAreOffline": "You are offline, please find a network connection.",
      "creditcard": "credit card",
      "paymentCollected": " payment sucessfully collected.",
      "paymentCollectedFailed": " payment failed to collect.",
    },
    "TITLE": {
      "backgroundPermissions": "to access to this device's location in the background?",
      "cancelAccount": "Cancel Account",
      "captureSignature": "Capture Signature",
      "confirmPassUsage": "Confirm Pass Usage",
      "criticalFailures": "The following items have been selected as critical failures",
      "deviceSetup": "Device Setup",
      "error": "Error",
      "gasReport": "Gas Report",
      "groupedDropoff": "Grouped Drop off",
      "groupedPickup": "Grouped Pickup",
      "groupedPickupAndDropoff": "Grouped Pickup and Drop off",
      "groupStop": "Group Stop",
      "info": "Info",
      "inspFailed": "Inspection Failed",
      "jobDetails": "Job Details",
      "jobPerform": "Perform Job",
      "messages": "Messages",
      "newPriorityMessage": "New Priority Message",
      "noConnection": "No Connection",
      "passengerNotify": "Passenger Notification",
      "passStatus": "Pass Status",
      "scanPass": "Scan Pass",
      "selectJobs": "Select Jobs",
      "selectServer": "Select Server",
      "settings": "Settings",
      "success": "Success",
      "tripModification": "Trip Modifications",
      "verifyOdometer": "Verify Odometer for Vehicle",
      "warning": "Warning",
      "youHaveArrived": "You have Arrived",
    },
    "PLACEHOLDER": {
      "enterValue": "Enter Value",
    },
    "UPDATE": {
      "appStore": "App Store",
      "cannotLogin": "You cannot log in until pending updates are sent to the server. Please find a network connection!",
      "immediateUpgrade": "This version of " + environment.appName + " is outdated, and we recommend you update immediately.\r\n",
      "openPlayStore": "Open Play Store",
      "playStore": "Play Store",
      "unableToUse": "You will be unable to log in without the update after:",
      "upgrade": "You are using an outdated version of " + environment.appName + ",\r\n",
    },
    "ROUTE_INFO": {
      "failedInspection": "This route has failed inspection",
      "noUpcoming": "No upcoming routes",
      "noVehicle": "No vehicle assigned, contact dispatch",
      "upcoming": "Upcoming Routes",
    },
    "DISCLAIMER": {
      "title": "Disclaimer (Scroll down to accept)",
      "termsTitle1": " You must read and accept the terms of this End User License Agreement for the use of all services rendered to you by " + environment.companyName + "’s mobile application, “" + environment.appName + ".” This application is designed to optimize a primarily hands-free experience. If you need to interact with the mobile device at any time, however",
      "termsTitle1Option1": "Follow local laws regarding mobile device usage",
      "termsTitle1Option2": "Follow any rules and regulations set forth by your company or organization",
      "termsTitle2": "The information, services, and application are provided without any express or implied warranty of fitness for a particular purpose, including correctness or completeness. " + environment.companyName + " is also entitled to change, suspend, or withdraw the contents of any services or products at any time without prior notice. " + environment.companyName + " cannot in any event be held liable for any or all damages resulting from the use of the " + environment.appName + " application",
      "termsTitle2Option1": "Do not interact with mobile devices while driving",
      "termsTitle2Option2": "Do not compose, send, or read any text or e-mail messages while driving",
      "termsTitle2Option3": "Do not use any other mobile applications or materials that will distract you or take your attention away from driving",
    },
    "SIGNATURE": {
      "signHere": "Use your finger to sign here",
      "sigCertify": "I certify that all information contained herein is true and accurate, and understand that this statement is made subject to the applicable penalties under federal and state law for making false declarations.",
    },
    "NO_SHOW_REASON": {
      "adminCancel": "Admin Cancel",
      "cancelAtDoor": "Cancel At Door",
      "noClient": "No Client",
      "notReady": "Not Ready",
      "notReadyWillReturn": "Not Ready, Will Return",
    },
    "MESSAGE": {
      "activityTrackLocationServiceSettings": "In order to track your activity in the background, please enable always in the Location Services settings",
      "enableTrackBackground": "In order to track your activity in the background, please enable",
      "getFlagStopPassInfoError": "Due to a network failure the pass cannot be verified. Please use the board button on the rider type you are boarding.",
      "genericError": "An error has occurred, please try again later",
      "jobsToPickup": "jobs have similar pickup times and locations.",
      "localPermission": "location permission",
      "missingActionType": "Missing or invalid action type",
      "noJobsToArrive": "No jobs find to arrive",
      "proceed": "Do you wish to proceed?",
      "scanAlreadyInProgress": "Scanner did not initiate correctly, the app will reload to fix the issue.",
      "selectToMarkArrive": "Select jobs to mark as having arrived",
      "selectToMarkOnTheWay": "Select jobs to mark as on the way",
      "selectToNotify": "Select passengers to notify",
      "undo": "This action will UNDO the last increment.",
      "undoBoard": "This action will UNDO a boarding action and does NOT deboard passengers.",
      "undoDeboard": "This action will UNDO a deboarding action and does NOT board passengers.",
    },
    "MOBILITY": {
      "ambulatory": "Ambulatory",
      "lift": "Lift Required",
      "stretcher": "Stretcher",
      "wheelchairSeats": "Wheel Chair Seats",
    },
  };


  /*
   * Please keep in alphabetical order to
   * - make it easier to find properties
   * - reduce merge conflicts
  */
  private readonly testEs = {
    "ACTION": {
      "accept": "Aceptar",
      "acknowledge": "reconocer",
      "allow": "Permitir",
      "answer": "Respuesta",
      "applicationValidated": "¡Solicitud Validada!",
      "arrive": "Llegar",
      "back": "Atrás",
      "cancel": "Cancelar",
      "captureAtDropOff": "Captura al Dejar",
      "changeTo": "Cambiar a",
      "checkUpdates": "Buscar actualizaciones",
      "clearSignature": "Borrar firma",
      "close": "Cerrar",
      "completeRoute": "Recorrido completo",
      "configureProvider": "Configurar proveedor",
      "confirm": "Confirmar",
      "confirmDenyRide": "¿Estás seguro de que quieres negar el paseo?",
      "decrement": "Decremento",
      "denyRide": "Negar viaje",
      "deviceInfo": "Información del dispositivo",
      "dismiss": "Despedir",
      "done": "Hecho",
      "dropOff": "Dejar",
      "dropOffs": "Bajadas",
      "fairmatic": "Fairmatic",
      "finish": "Terminar",
      "gasReport": "Informe de gas",
      "go": "Ir",
      "increment": "Incremento",
      "refresh": "Actualizar",
      "resume": "Reanudar",
      "login": "Inicia sesión",
      "logout": "Cerrar sesión",
      "menu": "Menú",
      "navigate": "Navegar",
      "navigateToEndDepot": "Navegar hasta el depósito final",
      "noShow": "No Show",
      "notify": "Notificar",
      "ok": "Ok",
      "okay": "Bien",
      "onboard": "A bordo",
      "onTheWay": "En el camino",
      "preview": "Vista previa",
      "provideRide": "Proporcionar viaje",
      "quickPerform": "Rendimiento rápido",
      "register": "Nuevo registro de conductor",
      "save": "Salvar",
      "sendMessage": "Enviar mensaje",
      "serviceCritical": "Servicio crítico",
      "serviceCriticalCritical": "Crítico",
      "serviceDueSoon": "Servicio debido pronto",
      "serviceDueSoonDue": "Pendiente",
      "scanPass": "Pase de escaneo",
      "start": "Empezar",
      "toggle": "Alternar",
      "removePass": "Eliminar pase",
      "sending": "Guardando",
      "submit": "Enviar",
      "testGPS": "Gps de prueba",
      "update": "Actualizar",
      "viewConsoleLog": "Ver registro de consola",
    },
    "ALT_TEXT": {
      "parascope": environment.appName + "para " + environment.companyName,
    },
    "LABEL": {
      "acceptedPassHas": "ha sido aceptado. El pase tiene",
      "account": "Cuenta",
      "accountCanceled": "Su cuenta ha sido marcada para su eliminación dentro de los próximos 5 días.",
      "acknowledged": "Admitido",
      "added": "Añadido",
      "additionalPassengerFare": "Tarifa de pasajero adicional",
      "addrChangedFrom": "la dirección ha cambiado de ",
      "advLogging": "Registro avanzado",
      "ambulatorySeats": "Asientos ambulatorios",
      "areYouSureCancelAccount": "¿Está seguro de que desea cancelar su cuenta de conductor?",
      "arrivedAtDestination": "Has llegado a tu destino",
      "arriveJobs": "¿Tú también quieres llegar a estos trabajos?",
      "assignedRider": "Rider asignado",
      "at": "en",
      "atDropoff": "en Dejar",
      "auth": "Autorización",
      "balanceRemaining": "Saldo restante",
      "boardedHere": "Abordado",
      "break": "Quebrar",
      "butPicking": "pero estás eligiendo",
      "callHistory": "Historial de llamadas",
      "callHistoryNoCalls": "No ha habido llamamientos justos en esta sesión.",
      "cancelAccount": "Cancelar cuenta de conductor",
      "cancelAccountNotLoggedIn": "Inicie sesión antes de cancelar su cuenta.",
      "cannotNavigate": "No se puede navegar a la ubicación porque no se ha geolocalizado",
      "cannotOpenNoGeo": "No se puede abrir la ubicación en un mapa porque no se ha geolocalizado",
      "cannotVerifyBalance": "No se puede verificar el saldo de pases. Por favor, recoja dinero en efectivo o pase que se cargará cuando la conexión esté disponible",
      "captureSignature": "Capturar firma",
      "cashCollected": "Efectivo recaudado",
      "changeMessages": "Cambiar mensajes",
      "checking": "Comprobación",
      "checkingPass": "Comprobando pase...",
      "children": "Niños",
      "close": "Cerrar",
      "completeStop": "Parada completa",
      "confirm": "Confirmar",
      "confirmArrival": "Confirmar llegada",
      "confirmDenyRide": "¿Estás seguro de que quieres negar este viaje?",
      "confirmRiderSign": "Confirmar firma del rider",
      "confirmYouAt": "Por favor, confirme que está en",
      "continue": "Continuar",
      "criticalInspFailed": "Las inspecciones críticas fallaron: comuníquese con su despachador",
      "currentBalance": "Saldo actual",
      "currentlyOnboard": "A Bordo",
      "currentRidesRemain": "Número actual de viajes restantes",
      "customer": "Cliente",
      "days": "días",
      "debitRideCount": "Recuento de viajes de débito",
      "deboard": "Desembarcar",
      "deBoardedHere": "Desembarcado",
      "deboardPass": "Pase de desembarque",
      "description": "Descripción",
      "deviceDescription": "Descripción del dispositivo",
      "deviceInfo": "Información del dispositivo",
      "disable": "Inutilizar",
      "doesNotExpire": "No caduca",
      "doNotHavePermission": "Si no tiene permiso para acceder a la",
      "driverIdNumber": "Identificación del conductor",
      "driverRegistered": "Registrado con éxito!",
      "driverRegistrationWait": "Si desea ser considerado para convertirse en un conductor, envíe su información a continuación. " + environment.companyName + " se pondrá en contacto en breve",
      "dropOff": "Dejar",
      "dropoffNoSpace": "Dejar",
      "effectiveDate": "Fecha de entrada en vigor",
      "emailAddress": "Correos electrónicos",
      "enterPassId": "Introduce el ID del pase",
      "escorts": "Escorts",
      "expiresOn": "Caduca el",
      "fare": "Tarifa",
      "fareCollected": "Tarifa cobrada",
      "fareOwed": "Tarifa adeudada",
      "fareType": "Tipo de tarifa",
      "finishingInsp": "Inspección de acabado...",
      "firstName": "Primer nombre",
      "flagStop": "Parada",
      "flagStopsOnBoard": "Total de pasajeros de la parada imprevista a bordo",
      "for": "Para",
      "gasCost": "Gas - Costo",
      "gasGallons": "Gas - Galones",
      "gasReportSent": "Informe de gas enviado",
      "groupNotInRange": "La ubicación del grupo no está dentro del alcance. ¿Estás seguro de que quieres llegar a este grupo?",
      "hasChangedFrom": "ha cambiado de",
      "help": "Ayuda",
      "inactivePass": "PASS ESTÁ INACTIVO",
      "insufficientErrorMessage": "EL ID DE PASE NO ES VÁLIDO",
      "insufficientFundsPass": "FONDOS INSUFICIENTES EN EL PASE",
      "insufficientRidesPass": "PASEOS INSUFICIENTES EN EL PASE",
      "invalidPIN": environment.features.requireVehicleNumber ? "Nombre de inicio de sesión o contraseña incorrectos" : "PIN no válido",
      "jobNotInRange": "La ubicación del trabajo no está dentro del alcance. ¿Estás seguro de que quieres llegar a este",
      "language": "Idioma",
      "lastName": "Apellido",
      "loadingMore": "Cargando más datos...",
      "loadMore": "Cargar más registros",
      "loggingIn": "Inicio de sesión",
      "map": "Mapa",
      "messageNotSent": "Mensaje no enviado, por favor seleccione una opción",
      "messageSent": "Mensaje enviado",
      "navigate": "Navegar",
      "no": "No",
      "noBeginning": "Sin comienzo",
      "noDeboard": "No se ha registrado ningún deboard",
      "noDeboardPass": "el pase ha sido desembrado",
      "noExpiration": "Sin espiratina",
      "noMessages": "Sin mensajes",
      "noRidersTypeOf": "Allí; son; no; jinetes; a bordo; con el jinete; tipo de",
      "noShow": "No Show",
      "noShowWithDash": "No-Show",
      "notAssigned": "No asignado",
      "notify": "Notificar",
      "notifyAdmin": "Tienda, notifique a su administrador",
      "notReady": "No listo",
      "numOfChildren": "Número de hijos",
      "numOfEscorts": "Número de Escorts",
      "numRidesRemain": "Número de viajes restantes",
      "odometer": "Odómetro",
      "onboard": "A bordo",
      "onboardPass": "Pase a bordo",
      "parentGuardianSig": "Padre / Tutor Firmado",
      "pass": "Pasar",
      "passAcceptedBalance": "ha sido aceptado. El pase tiene un balance de",
      "passAmount": "Monto del pase",
      "passBalance": "Saldo de pases",
      "passBelongs": "El pase pertenece a",
      "passDebited": "El pase será debitado",
      "passDebitedFor": "El pase será debitado por",
      "passEffective": "Pase efectivo",
      "passEffectiveDate": "Fecha de entrada en vigor del pase",
      "passengersOnBoard": "Pasajeros a bordo",
      "passExpiration": "Caducidad del pase",
      "passExpired": "PASE CADUCADO",
      "passless": "Sin pase",
      "passlessBoardingDeboarding": "Embarque y desembarque sin pase",
      "passNotEffective": "EL PASE AÚN NO ES EFECTIVO",
      "performFlagStop": "Realizar parada imprevista",
      "performGroupedStop": "Realizar parada agrupada",
      "performScheduledStop": "Realizar parada programada",
      "pickup": "Recogida",
      "pickupAndDropoff": "Recogida y devolución",
      "pickupFor": "Recogida para",
      "pickups": "Pastillas",
      "placeBarcode": "Coloque un código de barras dentro del área de escaneo",
      "pleaseCollect": "Por favor, recoja",
      "postTrip": "Post-Viaje",
      "preTrip": "Pre-Viaje",
      "previewRoute": "Vista previa de la ruta...",
      "priority": "Prioricia",
      "providerName": "Nombre del proveedor",
      "pullIn": "Entrar",
      "pullOut": "Salir",
      "rapidOnboardBluetooth": "Incorporación rápida desde Bluetooth",
      "rapidOnboardTablet": "Incorporación rápida desde la tableta",
      "ready": "Listo",
      "refreshingRoute": "Refrescante Ruta...",
      "remainBalanceAfterDebit": "Saldo restante del pase después del débito",
      "remaining": "restante",
      "remainingRides": "Paseos restantes",
      "remainRidesAfterDebit": "Saldo restante de los viajes después del débito",
      "removed": "Quitado",
      "reportJobsOTW": "¿También quieres reportar estos trabajos como en camino?",
      "reqDispatchCall": "Solicitar que se envíe la llamada",
      "reqSent": "Solicitud enviada",
      "reqSentFor": "Solicitud enviada para",
      "reservationSpecialDir": "Indicaciones especiales para reservas",
      "retakeSignature": "Recuperar la firma",
      "ride": "Montar",
      "rideCost": "Costo del viaje",
      "riderFareAccepted": "Se ha aceptado la tarifa del pasajero",
      "riderImage": "Imagen del jinete",
      "riderId": "Identificación del cliente",
      "riderName": "Nombre del ciclista",
      "riderPhone": "Teléfono Rider",
      "riderSigReceived": "Firma del rider recibida",
      "riderSpecDir": "Instrucciones especiales para el ciclista",
      "riderSpecialDir": "Instrucciones especiales para el ciclista",
      "riderUnableSig": "Rider Unable to Sign – Facility Signed",
      "rides": "Paseos",
      "ridesRemain": "Paseos restantes",
      "ridesRemainAfter": "paseos restantes después de este y",
      "route": "Ruta",
      "runningInBackground": "Ejecutando en segundo plano",
      "scannedPassNotFound": "El pase escaneado no se puede encontrar a bordo. Utilice el botón de desembarque en el tipo de pasajero que está desembarcando.",
      "scanningFailed": "Error de análisis",
      "scanningField": "Campo de escaneo",
      "scheduled": "programado",
      "scheduledStop": "Parada Programada",
      "scheduledTime": "Hora programada",
      "seeOn": "Ver en",
      "selectMessage": "Seleccionar mensaje",
      "signature": "Firma",
      "sigPerformDropOff": "La firma se realizará en la entrega",
      "snapshot": "Instantánea",
      "startingRoute": "Ruta de inicio...",
      "stop": "parar",
      "success": "Éxito",
      "tapForDetails": "Toca para obtener más información",
      "timePoint": "Punto de tiempo",
      "to": "Para",
      "today": "Este Dia",
      "total": "Total",
      "totalPassengersOnBoard": "Total de pasajeros a bordo",
      "units": "Unidades",
      "unlimited": "Ilimitado",
      "unscheduled": "Imprevistas",
      "up": "hacia arriba",
      "validateApp": "Validar solicitud",
      "vehicle": "Vehículo",
      "vehicleInspChecklist": "Lista de verificación de inspección de vehículos",
      "vehicleNumber": "Identificación del vehículo",
      "viewIn": "Ver en",
      "waitWhileRouteValidating": "Espere mientras se valida su ruta.",
      "wheelchairStations": "Estaciones para sillas de ruedas",
      "willCall": "Llamará",
      "yes": "Sí",
      "youAreOffline": "Estás desconectado, encuentra una conexión de red.",
      "creditcard": "tarjeta de crédito",
       "paymentCollected": " pago cobrado exitosamente.",
      "paymentCollectedFailed": " pago no se pudo cobrar.",
    },
    "TITLE": {
      "backgroundPermissions": "para acceder a la ubicación de este dispositivo en segundo plano?",
      "cancelAccount": "Cancelar cuenta",
      "captureSignature": "Firma de captura",
      "confirmPassUsage": "Confirmar el uso del pase",
      "criticalFailures": "Los siguientes elementos han sido seleccionados como fallas críticas.",
      "deviceSetup": "Configuración del dispositivo",
      "error": "Error",
      "gasReport": "Informe de gas",
      "groupedDropoff": "Entrega agrupada",
      "groupedPickup": "Recogida agrupada",
      "groupedPickupAndDropoff": "Recogida y regreso grupales",
      "groupStop": "Parada de grupo",
      "info": "Información",
      "inspFailed": "Error de inspección",
      "jobDetails": "Detalles del trabajo",
      "jobPerform": "Realizar trabajo",
      "messages": "Mensajes",
      "newPriorityMessage": "Nuevo mensaje de prioridad",
      "noConnection": "Sin Conexión",
      "passengerNotify": "Notificación al pasajero",
      "passStatus": "Estado del pase",
      "scanPass": "Pase de escaneo",
      "selectJobs": "Seleccionar trabajos",
      "selectServer": "Seleccionar Servidor",
      "settings": "Configuración",
      "success": "Éxito",
      "tripModification": "Modificaciones de viaje",
      "verifyOdometer": "Verificar el odómetro del vehículo",
      "warning": "Advertencia",
      "youHaveArrived": "Has llegado",
    },
    "PLACEHOLDER": {
      "enterValue": "Introduzca un valor",
    },
    "UPDATE": {
      "appStore": "Tienda de aplicaciones",
      "cannotLogin": "No puede iniciar sesión hasta que se envíen las actualizaciones pendientes al servidor. ¡Encuentre una conexión de red!",
      "immediateUpgrade": "Esta versión de " + environment.appName + " está desactualizada y le recomendamos que la actualice de inmediato.\r\n",
      "openPlayStore": "Abre Play Store",
      "playStore": "Tienda de juegos",
      "unableToUse": "No podrá iniciar sesión sin la actualización después de:",
      "upgrade": "Está utilizando una versión obsoleta de " + environment.appName + ",\r\n",
    },
    "ROUTE_INFO": {
      "noVehicle": "No hay vehículo asignado, despacho de contacto",
      "noUpcoming": "No hay próximas rutas",
      "upcoming": "Próximas rutas",
      "failedInspection": "Esta ruta ha fallado en la inspección",
    },
    "DISCLAIMER": {
      "title": "Descargo de responsabilidad (Desplácese hacia abajo para aceptar)",
      "termsTitle1": " Debe leer y aceptar los términos de este Acuerdo de licencia de usuario final para el uso de todos los servicios que le brinda la aplicación móvil de " + environment.companyName + ", \"" + environment.appName + "\". Esta aplicación está diseñada para optimizar una experiencia principalmente manos libres. Sin embargo, si necesita interactuar con el dispositivo móvil en cualquier momento",
      "termsTitle1Option1": "Siga las leyes locales con respecto al uso de dispositivos móviles",
      "termsTitle1Option2": "Siga las reglas y regulaciones establecidas por su empresa u organización",
      "termsTitle2": "La información, los servicios y la aplicación se proporcionan sin ninguna garantía expresa o implícita de idoneidad para un propósito particular, incluida la corrección o integridad. " + environment.companyName + " también tiene derecho a cambiar, suspender o retirar el contenido de cualquier servicio o producto en cualquier momento sin previo aviso. " + environment.companyName + " no se hace responsable, en ningún caso, de ninguno o todos los daños resultantes del uso de la aplicación " + environment.appName + ".",
      "termsTitle2Option1": "No interactúe con dispositivos móviles mientras conduce",
      "termsTitle2Option2": "No redacte, envíe ni lea ningún mensaje de texto o correo electrónico mientras conduce",
      "termsTitle2Option3": "No utilice ninguna otra aplicación móvil o material que lo distraiga o le quite la atención de la conducción.",
    },
    "SIGNATURE": {
      "signHere": "Usa tu dedo para firmar aquí",
      "sigCertify": "Certifico que toda la información contenida en este documento es verdadera y precisa, y entiendo que esta declaración está sujeta a las sanciones aplicables bajo la ley federal y estatal por hacer declaraciones falsas.",
    },
    "NO_SHOW_REASON": {
      "noClient": "Sin cliente",
      "cancelAtDoor": "Cancelar en puerta",
      "notReady": "No listo",
      "adminCancel": "Administrador Cancelar",
      "notReadyWillReturn": "No listo, volverá",
    },
    "MESSAGE": {
      "activityTrackLocationServiceSettings": "Para realizar un seguimiento de su actividad en segundo plano, habilítela siempre en la configuración de Servicios de ubicación",
      "enableTrackBackground": "Para realizar un seguimiento de su actividad en segundo plano, habilite",
      "getFlagStopPassInfoError": "Debido a una falla en la red, el pase no se puede verificar. Utilice el botón de abordar del tipo de pasajero que está abordando.",
      "genericError": "Se ha producido un error, inténtelo de nuevo más tarde.",
      "jobsToPickup": "trabajos tienen horarios y ubicaciones de recogida similares.",
      "localPermission": "permiso de ubicación",
      "missingActionType": "Tipo de acción faltante o no válida",
      "noJobsToArrive": "No se encuentran trabajos para llegar",
      "proceed": "¿Desea continuar?",
      "scanAlreadyInProgress": "El escáner no se inició correctamente, la aplicación se recargará para solucionar el problema.",
      "selectToMarkArrive": "Seleccionar trabajos para marcar como llegados",
      "selectToMarkOnTheWay": "Seleccione trabajos para marcar como en camino",
      "selectToNotify": "Seleccionar pasajeros a notificar",
      "undo": "Esta acción DESHARÁ el último incremento.",
      "undoBoard": "Esta acción DESHARÁ una acción de embarque y NO desembarcará a los pasajeros.",
      "undoDeboard": "Esta acción DESHARÁ una acción de desembarque y NO aborda pasajeros.",
    },
    "MOBILITY": {
      "ambulatory": "Ambulatorio",
      "lift": "Se requiere ascensor",
      "stretcher": "Camilla",
      "wheelchairSeats": "Asientos para sillas de ruedas",
    },
  };

  constructor(
    private readonly ngxTranslate: ngxTranslate,
  ) {
    for (var prop in this.testEn) {
      this.testEn[prop] = { ...this.testEn[prop] };
      this.testEs[prop] = { ...this.testEs[prop] };
    }
  }

  /**
   * Initial set up,
   * defines languages available,
   * assigns the default fallback language
   * set's the user's language
   * @returns Language
   */
  public init(): Language {

    //english
    this.ngxTranslate.setTranslation('en', this.testEn, true);

    //spanish
    this.ngxTranslate.setTranslation('es', this.testEs, true);

    //  Set languages
    this.ngxTranslate.addLangs(this.SUPPORTED_LANGUAGES);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.ngxTranslate.setDefaultLang('en');

    const language = this.getUserLanguagePreference();

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.applyLanguageToLibraries(language);
    this.currentLanguage$.next(language);

    return language;
  }

  /**
   * Finds the user's language settings
   * @returns Language
   */
  public getUserLanguagePreference(): Language {

    let language: any = localStorage.getItem(this.KEY);

    if (!language || !this.SUPPORTED_LANGUAGES.includes(language)) {
      const deviceLanguage: any = window.navigator.language;
      //  strip regional code
      if (deviceLanguage && deviceLanguage.length > 2) { language = deviceLanguage.substring(0, 2); }
      language = deviceLanguage && this.SUPPORTED_LANGUAGES.includes(deviceLanguage) ? deviceLanguage : 'en';
      this.saveLanguage(language);
    }

    return language as Language;
  }

  /**
   * Set's new which language the user wishes to see the content in
   * @param language
   */
  public setLanguage(language: Language): void {
    this.applyLanguageToLibraries(language);
    this.saveLanguage(language);
    this.currentLanguage$.next(language);
  }

  /**
   * Takes the JSON reference value and returns the text in the appropriate language
   * @param value
   * @returns string
   */
  public translate(value: string): string {
    if (!value) { return ''; }
    return this.ngxTranslate.instant(value);
  }

  /**
   * Save language to local storage
   * @param language
   */
  private saveLanguage(language: string): void {
    localStorage.setItem(this.KEY, language);
  }

  private applyLanguageToLibraries(language: string): void {
    this.ngxTranslate.use(language);
    moment.locale(language);
  }

}

export type Language = 'en' | 'es';
