import { Injectable } from "@angular/core";
import { Route } from "./models/route";
import { RouteSummary } from "./models/route-summary";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { GpsReport } from "./models/gps-report";
import { UserSession } from "./models/user-session.type";
import { environment } from "src/environments/environment";
import { LocalSettings, defaultLocalSettings } from "./models/local-settings";
import { FareType } from "./models/login";
import { Device, getDefaultDevice } from "./models/device";
import { ConnectionStatus } from "@capacitor/network";
import { NoShowOptions } from "./models/no-show-option";
import { ScheduledFlagStop } from "./models/flag-stop";
import { Job } from "./models/job";
import { DispatchMessage } from "./models/dispatch-message";
import { PredefinedMessage } from "./models/predefined-message";

export const GPS_REPORT_INTERVAL_DURATION = 30000;
export const SESSION_KEY = 'ParaScopeSession';
export const LOCAL_SETTINGS_KEY = 'LOCAL_SETTINGS';
export const DEFAULT_DISPATCH_URL = environment.defaultDispatcherUrl;
export const DEPRECATED_LEGACY_DISPATCHER_URL_1 = 'https://parascopecore.azurewebsites.net/api/tabletDispatcher/';
export const DEPRECATED_LEGACY_DISPATCHER_URL_2 = 'https://parascopev2.azurewebsites.net/api/tabletDispatcher/';

@Injectable({ providedIn: 'root' })
export class AppData {

  // connectionStatus
  protected _connectionStatus: ConnectionStatus;
  get connectionStatus() { return this._connectionStatus; }
  get online() { return this._connectionStatus.connected; }
  get offlineMode() { return !this._connectionStatus.connected; }
  get connectionType() { return this._connectionStatus.connectionType; }
  //  replay here because we don't want to guess on status,
  //  won't emit until it has a value, but if has one, you'll get the last set
  private _connectionStatus$ = new ReplaySubject<ConnectionStatus>(1);
  public readonly connectionStatus$ = this._connectionStatus$.asObservable();

  // currentRouteIsPreview
  protected _currentRouteIsPreview: boolean = false;
  get currentRouteIsPreview() { return this._currentRouteIsPreview; }
  private _currentRouteIsPreview$ = new BehaviorSubject<boolean>(this._currentRouteIsPreview);
  public readonly currentRouteIsPreview$ = this._currentRouteIsPreview$.asObservable();

  // device
  protected _device: Device = getDefaultDevice();
  get device() { return this._device; }
  private _device$ = new BehaviorSubject(this.device);
  public readonly device$ = this._device$.asObservable();

  // driverIsActivelyReporting
  protected _driverIsActivelyReporting: boolean = false;
  get driverIsActivelyReporting() { return this._driverIsActivelyReporting; }
  private _driverIsActivelyReporting$ = new BehaviorSubject<Boolean>(this.driverIsActivelyReporting);
  public readonly driverIsActivelyReporting$ = this._driverIsActivelyReporting$.asObservable();

  // fairTypes
  protected _fareTypes: FareType[] = [];
  get fareTypes() { return this._fareTypes; }
  private _fareTypes$ = new BehaviorSubject<FareType[]>([]);
  public readonly fareTypes$ = this._fareTypes$.asObservable();

  // gpsReport
  protected _gpsReport: GpsReport = <GpsReport>{};
  get gpsReport() { return this._gpsReport; }
  private _gpsReport$ = new BehaviorSubject<GpsReport>(this.gpsReport);
  public readonly gpsReport$ = this._gpsReport$.asObservable();

  // isDark -- Theme
  protected _isDark = environment.features.defaultTheme === 'dark';
  get isDark() { return this._isDark; }
  private _isDark$ = new BehaviorSubject<boolean>(this.isDark);
  public readonly isDark$ = this._isDark$.asObservable();

  // localSettings
  protected _localSettings: LocalSettings = { ...defaultLocalSettings };
  get localSettings() { return this._localSettings; }
  private _localSettings$ = new BehaviorSubject<LocalSettings>(this.localSettings);
  public readonly localSettings$ = this._localSettings$.asObservable();

  // messages
  protected _messages: DispatchMessage[] = [];
  get messages() { return this._messages; }
  private _messages$ = new BehaviorSubject<DispatchMessage[]>(this.messages);
  public readonly messages$ = this._messages$.asObservable();

  // new messages -- for notification
  protected _priorityMessages: DispatchMessage[] = [];
  get priorityMessages() { return this._priorityMessages; }
  private _priorityMessages$ = new BehaviorSubject<DispatchMessage[]>(this.priorityMessages);
  public readonly priorityMessages$ = this._priorityMessages$.asObservable();

  // noShowOptions
  protected _noShowOptions: NoShowOptions[] = [];
  get noShowOptions() { return this._noShowOptions; }
  private _noShowOptions$ = new BehaviorSubject<NoShowOptions[]>(this.noShowOptions);
  public readonly noShowOptions$ = this._noShowOptions$.asObservable();

  // paraPassEnabled
  protected _paraPassEnabled: boolean = false;
  get paraPassEnabled() { return this._paraPassEnabled; }
  private _paraPassEnabled$ = new BehaviorSubject<boolean>(this.paraPassEnabled);
  public readonly paraPassEnabled$ = this._paraPassEnabled$.asObservable();

  //  predefinedMessages
  protected _predefinedMessages: PredefinedMessage[] = [];
  get predefinedMessages() { return this._predefinedMessages; }
  private _predefinedMessages$ = new BehaviorSubject<PredefinedMessage[]>(this._predefinedMessages);
  public readonly predefinedMessages$ = this._predefinedMessages$.asObservable();

  // rapidPassEnabled
  protected _rapidPassEnabled: boolean = false;
  get rapidPassEnabled() { return this._rapidPassEnabled; }
  private _rapidPassEnabled$ = new BehaviorSubject<boolean>(this.rapidPassEnabled);
  public readonly rapidPassEnabled$ = this._rapidPassEnabled$.asObservable();

  // route
  protected _route: Route = <Route>{};
  get route() { return this._route; }
  private _route$ = new BehaviorSubject<Route>(this.route);
  public readonly route$ = this._route$.asObservable();
  getJob(rideId: number, jobType: number): Job | undefined { return this.route.jobs.find(j => j.rideId === rideId && j.jobType === jobType ); }

  //  route notifications
  protected _routeNotifications: string[] = [];
  get routeNotifications() { return this._routeNotifications; }
  //  dumb subject here because we only care about what comes in once we subscribe,
  //  don't care about what happened in the past
  private _routeNotifications$ = new Subject<string[]>();
  public readonly routeNotifications$ = this._routeNotifications$.asObservable();

  // routeSummaries
  protected _routeSummaries: RouteSummary[] | null = null;
  get routeSummaries() { return this._routeSummaries; }
  private _routeSummaries$ = new BehaviorSubject<RouteSummary[] | null>(null);
  public readonly routeSummaries$ = this._routeSummaries$.asObservable();

  // scheduledFlagStops
  protected _scheduledFlagStops: ScheduledFlagStop[] = [];
  get scheduledFlagStops() { return this._scheduledFlagStops; }
  private _scheduledFlagStops$ = new BehaviorSubject<ScheduledFlagStop[]>([]);
  public readonly scheduledFlagStops$ = this._scheduledFlagStops$.asObservable();

  //  userSession
  private _userSession: UserSession = null;
  get userSession() { return this._userSession; }
  get sessionId() { return this._userSession?.sessionId || null; }
  private _userSession$ = new BehaviorSubject<UserSession | null>(null);
  public userSession$ = this._userSession$.asObservable();

  set<T>(key: keyof AppData, value: T): void {

    switch(key) {
      case 'localSettings': {
        if (!value) { return; }
        const { customerId, uuid, customerName } = <LocalSettings>value;
        const device = { ...this.device, identity: { ...(this.device?.identity || {}), customerId, uuid, customerName }};
        this._set('localSettings', value);
        this._set('device', device);
        break;
      }
      case 'route':
        if (!value) { this._set(key, value); return; }
        let route = <Route>value;
        const scheduledFlagStops = route.fixedRoutesEnabled
          ? this.applyPerformToStops(route.scheduledFlagStops)
          : [];
        route = { ...route, scheduledFlagStops };

        this._set('route', route);
        this._set('scheduledFlagStops', scheduledFlagStops);
        break;
      default: this._set(key, value);
    }
  }

  public setJobs(updates: Job[]): void {
    const updatedJobs = this.route.jobs.map(storedJob => {
      const updatedJob = updates.find(updatedJob => updatedJob.rideId === storedJob.rideId && updatedJob.jobType === storedJob.jobType);
      if (!updatedJob) { return storedJob; }
      return { ...storedJob, ...updatedJob };
    });
    this.set('route', { ...this.route, jobs: updatedJobs });
  }

  private _set<T>(key: keyof AppData, value: T): void {
    this[`_${key}`] = value;
    this[`_${key}$`].next(value);
  }

  private applyPerformToStops(stops: ScheduledFlagStop[]): ScheduledFlagStop[] {
    if (!stops) { return []; }
    const flagStopCount = stops.length;
    let performRecorded = false;
    for (let i = (flagStopCount - 1); i >= 0; i--) {
      const stop = stops[i];
      if (performRecorded) {
        // set all stops above this one to performed!
        stop.enablePerform = false;
      } else {
        if (stop.performed) { performRecorded = true; }
        stop.enablePerform = !stop.performed;
      }
    }
    return stops;
  }

  /** reset on logout */
  public reset() {
    this._set('driverIsActivelyReporting', false);
    this._set('fareTypes', []);
    this._set('messages', []);
    this._set('noShowOptions', []);
    this._set('paraPassEnabled', false);
    this._set('predefinedMessages', []);
    this._set('rapidPassEnabled', false);
    this._set('route', {});
    this._set('routeNotifications', []);
    this._set('routeSummaries', []);
    this._set('scheduledFlagStops', []);
  }

}
