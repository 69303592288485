import { AppData, GPS_REPORT_INTERVAL_DURATION } from '../data';
import { EMPTY, Observable, Subject, firstValueFrom, from, interval, of } from 'rxjs';
import { FlagStopJob, FlagStopPerform, ScheduledFlagStop } from '../models/flag-stop';
import { Injectable, inject } from '@angular/core';
import { InspectionItem, InspectionItemResult } from '../models/inspection';
import { Job, JobPerform, JobType, OnTheWay, buildJobArrive } from '../models/job';
import { LoginCredentials, LoginResponse } from '../models/login';
import { NonServicePeriod, NonServicePeriodActionEnum, NonServicePeriodTypeEnum } from '../models/non-service-period';
import { PassDetails, PassInformationRequestObject } from '../models/pass';
import { RouteStatusUpdate, RouteStatusUpdateResult, concatStatusUpdates, createNewStatusUpdate } from '../models/route-status-update';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CallRequest } from '../models/call-request';
import { CompleteRoute } from '../models/complete-route';
import { Device } from '../models/device';
import { DeviceService } from './device.service';
import { ErrorHandlerService } from './error-handler.service';
import { FairmaticService } from './fairmatic.service';
import { FairmaticStatusChange } from '../models/fairmatic-status-change';
import { Fluids } from '../models/fluids';
import { GpsReport } from '../models/gps-report';
import { LocalSettings } from '../models/local-settings';
import { LocationService } from './location.service';
import { LoggingService } from './logging.service';
import { Logout } from '../models/logout';
import { MessageResponse } from '../models/message-response';
import { RegistrationResult } from '../models/registration';
import { Route } from '../models/route';
import { RouteUpdate } from '../models/route-update';
import { SendPredefinedMessage } from '../models/send-predefined-message';
import { ServerProxyService } from './server-proxy.service';
import { SessionService } from './session.service';
import { SettingsService } from './settings.service';
import { StoragePluginService } from './plugin/storage-plugin.service';
import { TranslateService } from './translate.service';
import { UtilityService } from './utility.service';
import { VersionService } from './version.service';
import { environment } from 'src/environments/environment';
import { MessageResponseEnum } from '../models/dispatch-message';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DispatcherService {

  private readonly data = inject(AppData);

  private gpsReportInterval = interval(GPS_REPORT_INTERVAL_DURATION);
  private timer: any;

  private needUpdateFromServer = false;
  private retryCount = 0;
  private serverUpdateInterval = 5 * 1000;
  private messageId: string = null;

  private readonly maxServerUpdateInterval: number = 120 * 1000;
  private readonly pendingUpdatesStorageKey = 'Pending_Updates';
  private readonly inProcessUpdatesStorageKey = 'In_Process_Updates';

  public readonly onUserLoggedOut: Subject<RouteStatusUpdateResult> = new Subject();

  protected _pendingUpdatesExist = false;
  public get pendingUpdatesExist() { return this._pendingUpdatesExist; }
  private currentlyProcessingUpdates = false;
  private logFairmaticAuditEvents: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private errorHandler: ErrorHandlerService,
    private fairmaticService: FairmaticService,
    private locationService: LocationService,
    private loggingService: LoggingService,
    private sessionService: SessionService,
    private storageService: StoragePluginService,
    private proxy: ServerProxyService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private versionService: VersionService,
    private settingsService: SettingsService,
  ) {}

  public logFairmaticEvents(logEnabled: boolean) {
    this.logFairmaticAuditEvents = logEnabled;
  }

  public async triggerUpdates() {
    if (!this.needUpdateFromServer) {
      clearTimeout(this.timer);
      // this is used for mocking scenarios on the client and forcing the client to ask the mock server for updates
      this.needUpdateFromServer = true;
      await this.sendPendingUpdates();
    }
  }

  async init() {
    this._pendingUpdatesExist = await this.pendingUpdatesKeysExist();
    await this.sendPendingUpdates();
  }

  public async handleFairmaticStatusChangeEvent(statusChangeEvent: FairmaticStatusChange) {
    if (!this.logFairmaticAuditEvents) {
      return; // don't do anything if not auditing
    }
    if (!statusChangeEvent.end) { return; }
    // add the fairmatic event to the current update
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.fairmaticStatusEvents = [ statusChangeEvent ];
    await this.addPendingUpdate(update);
  }

  private registerDeviceApiCall(device: Device): Observable<RegistrationResult> {
    return this.proxy.registerDevice(device);
  }

  async forceRemovePendingUpdates() {
    this._pendingUpdatesExist = await this.pendingUpdatesKeysExist();
    if (this._pendingUpdatesExist) {
      await this.removePendingUpdates();
      this._pendingUpdatesExist = false;
    }
  }

  async registerDevice(): Promise<RegistrationResult> {
    const _device = this.data.device;
    const { version } = await this.versionService.getAppInfo() || { };
    const { channel } = this.versionService.config || { };
    const { buildId } = this.versionService.snapshotInfo || { };
    let localSettings = this.data.localSettings;

    const device: Device = {
      ..._device,
      description: _device.description || localSettings.description,
      identity: {
        ..._device.identity,
        customerName: _device.identity.customerName || localSettings.customerName,
        customerId: _device.identity.customerId || localSettings.customerId,
      },
      softwareVersion: {
        ionicChannel: channel,
        playStoreVersion: version,
        buildVersion: buildId,
        ionicSnapshotBuildId: buildId,
      },
    };

    this.loggingService.setMetaData(
      { key: 'customerName', value: device.identity.customerName },
      { key: 'description', value: device.description },
    );

    const response = await firstValueFrom(this.registerDeviceApiCall(device));
    const { ionicChannel, customerId, customerName } = response;
    this.versionService.config.channel = !ionicChannel || ionicChannel === 'configurable' ? this.getChannel(ionicChannel) : ionicChannel;
    localSettings = this.data.localSettings;
    const updatedSettings: LocalSettings = {
      ...localSettings,
      customerId,
      customerName,
    };
    await this.settingsService.saveSettings(updatedSettings);
    device.softwareVersion.ionicChannel = ionicChannel;
    this.deviceService.updateDeviceInformation({ ...device, softwareVersion: device.softwareVersion });
    return response;
  }

  getChannel(channel: string) {
    if (channel && channel !== 'configurable') { return channel; }
    const settings = this.data.localSettings;
    const _environment = environment.dispatcherEndpoints.find(ep => ep.url === settings.dispatcherUrl);
    if (!_environment) { return environment.defaultChannel; }
    const _channel = environment.channels.find(ch => ch.name === _environment.name);
    if (!_channel) { return environment.defaultChannel; }
    return _channel.channel;
  }

  login(login: LoginCredentials): Observable<LoginResponse> {
    return this.proxy.login(login);
  }

  async addInspectionReport(inspectionItems: InspectionItem[], type: NonServicePeriodTypeEnum) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    const resultUpdates: InspectionItemResult[] = [];
    const now = new Date();
    const _gpsReport = this.data.gpsReport;
    const gpsReport: GpsReport  = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };

    for (const item of inspectionItems) {
      resultUpdates.push({
        inspectionItemId: item.inspectionItemId,
        result: (item.questionType === 0) ? item.result.toString() : item.textResult,
      });
    }
    if (type === NonServicePeriodTypeEnum.PreTrip) {
      update.preTripInspection = resultUpdates;
    } else if (type === NonServicePeriodTypeEnum.PostTrip) {
      update.postTripInspection = resultUpdates;
    }
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];
    await this.addPendingUpdate(update);
  }

  async completeRoute(driverSignature: string, _gpsReport: GpsReport) {
    const now = new Date();

    const gpsReport: GpsReport  = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };

    const request: CompleteRoute = {
      sessionId: this.data.sessionId,
      driverSignature: driverSignature,
      gpsReport,
    };

    this.locationService.logToTrackJS(_gpsReport, gpsReport);

    await this.fairmaticService.zendriveAction('goOffDuty')
      .catch(error => console.error('[Zendrive]: Error occurred going off duty in fairmatic', error));

    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.completeRoute = request;
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];

    const routeId = this.data.route.id;
    const routeSummaries = this.data.routeSummaries.filter(rs => rs.id !== routeId);
    this.data.set('routeSummaries', routeSummaries);

    await this.addPendingUpdate(update);
    this.data.set('driverIsActivelyReporting', false);
    await this.sendPendingUpdates();
  }


  async onTheWays(onTheWays: OnTheWay[]) {
    console.debug('ON THE WAYS', onTheWays);
    const _gpsReport = this.data.gpsReport;
    const now = new Date();
    const gpsReport: GpsReport = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    this.locationService.logToTrackJS(_gpsReport, gpsReport);
    for (const arrival of onTheWays) {
      arrival.gpsReport = gpsReport;
    }
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.onTheWays = onTheWays;
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];
    await this.addPendingUpdate(update);
  }

  async arriveJobs(jobs: Job[]) {
    console.debug('ARRIVE JOB', jobs);
    const arriveTime = new Date();
    const gpsReport: GpsReport = {
      ...this.data.gpsReport,
      dateTimeReported: arriveTime,
      occuranceDate: arriveTime,
    };
    this.locationService.logToTrackJS(this.data.gpsReport, gpsReport);
    const jobArrivals = jobs.map(job => buildJobArrive({ job, gpsReport }));
    const update: RouteStatusUpdate = {
      ...createNewStatusUpdate(this.data.sessionId),
      jobArrivals,
      gpsReports: [ gpsReport ],
    };
    await this.addPendingUpdate(update);
    this.data.setJobs(jobs.map(job => ({ ...job, arriveTime })));
  }

  async logout(): Promise<void> {
    const _gpsReport: GpsReport = this.data.gpsReport;
    const now = new Date();
    const gpsReport: GpsReport = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    this.locationService.logToTrackJS(_gpsReport, gpsReport);
    this.versionService.resetUpgradeVariables();

    const request: Logout = { sessionId: this.data.sessionId, gpsReport: gpsReport };
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.logout = request;
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];

    // tell fairmatic we are going off duty (logging for failures already happens in the service)
    this.fairmaticService.zendriveAction('goOffDuty')
      .catch(err => console.error('[Zendrive]: Error occurred going off duty in fairmatic', err));

    await this.addPendingUpdate(update);
    await this.sendPendingUpdates();

    //  clear and reset data
    await this.locationService.stop();
    this.fairmaticService.clear();
    this.sessionService.clearSession();
    this.data.reset();
  }

  async getRouteUpdate(): Promise<RouteUpdate> {
    const routeUpdate = await this.proxy.routeUpdate();
    if (!routeUpdate) {
      return null;
    }
    return this.routeSetup(routeUpdate);
  }

  async performJob(jobPerform: JobPerform, passengersAreOnBoardAfterPerform: boolean) {
    const _gpsReport = this.data.gpsReport;
    const now = new Date();
    const gpsReport = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    jobPerform.gpsReport = gpsReport;
    this.locationService.logToTrackJS(_gpsReport, gpsReport);

    // tell fairmatic we are picking someone up or dropping someone off (logging for failures already happens in the service)
    try {
      if (jobPerform.jobType === JobType.Pickup) {
        await this.fairmaticService.zendriveAction('pickupPassenger');
      } else if (!passengersAreOnBoardAfterPerform) {
        await this.fairmaticService.zendriveAction('onTheWay');
      }
    } catch (err) {
      console.error('[Zendrive]: Error occurred doing a job perform in fairmatic.', err);
    }

    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.jobPerforms.push(jobPerform);
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];
    // push the new data
    await this.addPendingUpdate(update);

  }

  async performNonServicePeriod(action: NonServicePeriodActionEnum, type: NonServicePeriodTypeEnum, overrideDate: Date = null) {
    const _gpsReport = this.data.gpsReport;
    const now = new Date();
    const gpsReport: GpsReport = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    this.locationService.logToTrackJS(_gpsReport, gpsReport);
    if (overrideDate) { gpsReport.occuranceDate = overrideDate; }
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];

    switch (type) {
      case NonServicePeriodTypeEnum.PreTrip:
        if (action === NonServicePeriodActionEnum.Start) {
          update.preTripStart = gpsReport;
          this.data.set('route', { ...this.data.route, preTrip: { ...this.data.route.preTrip, hasBeenStarted: true }});
        } else {
          update.preTripEnd = gpsReport;
          this.data.set('route', { ...this.data.route, preTrip: { ...this.data.route.preTrip, hasBeenCompleted: true }});
        }
        break;
      case NonServicePeriodTypeEnum.Break:
        if (action === NonServicePeriodActionEnum.Start) {
          update.breakStart = gpsReport;
          this.data.set('route', { ...this.data.route, break: { ...this.data.route.break, hasBeenStarted: true }});
        } else {
          update.breakEnd = gpsReport;
          this.data.set('route', { ...this.data.route, break: { ...this.data.route.break, hasBeenCompleted: true }});
        }
        break;
      case NonServicePeriodTypeEnum.PostTrip:
        if (action === NonServicePeriodActionEnum.Start) {
          update.postTripStart = gpsReport;
          this.data.set('route', { ...this.data.route, postTrip: { ...this.data.route.postTrip, hasBeenStarted: true }});
        } else {
          update.postTripEnd = gpsReport;
          this.data.set('route', { ...this.data.route, postTrip: { ...this.data.route.postTrip, hasBeenCompleted: true }});
        }
        break;
    }

    await this.addPendingUpdate(update);
  }

  async dismissDispatchMessage(response: MessageResponse) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.messageResponses.push(response);
    await this.addPendingUpdate(update);
  }

  async predefinedMessage(message: SendPredefinedMessage) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.predefinedMessages.push(message);
    await this.addPendingUpdate(update);
  }

  // begin route status update functions
  async callRequest(request: CallRequest) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.callRequests.push(request);
    await this.addPendingUpdate(update);
  }

  async performFlagStop(flagStop: ScheduledFlagStop, jobs: FlagStopJob[] = [], currentNumberOnboard: number = null): Promise<void> {
    const now = new Date();
    const gpsReport: GpsReport = {
      ...this.data.gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    this.locationService.logToTrackJS(gpsReport, this.data.gpsReport);
    const { id, departureTime, arrivalTime } = flagStop || {};
    const flagStopPerform: FlagStopPerform = <FlagStopPerform>{
      completedDate: now,
      gpsReport,
      scheduledFlagStopId: id,
      scheduledDepartureDate: departureTime,
      scheduledArrivalDate: arrivalTime,
      jobs,
    };
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.flagStopPerforms.push(flagStopPerform);
    update.gpsReports = [ ...(update.gpsReports || []), gpsReport ];

    const _flagStop = this.data.scheduledFlagStops.find(fs => fs.id === id) || <ScheduledFlagStop>{};
    if (_flagStop) _flagStop.performed = true;
    if (currentNumberOnboard) {
      this.data.route.riderTypes.forEach(riderType => { riderType.currentNumberOnboard = currentNumberOnboard; });
    }
    this.data.set('scheduledFlagStops', this.data.scheduledFlagStops);
    await this.addPendingUpdate(update);
  }

  async fluidsReport(request: Fluids) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    update.fluids = request;
    await this.addPendingUpdate(update);
  }

  async gpsReport(_gpsReport: GpsReport) {
    const update: RouteStatusUpdate = createNewStatusUpdate(this.data.sessionId);
    const now = new Date();
    const gpsReport: GpsReport = {
      ..._gpsReport,
      dateTimeReported: now,
      occuranceDate: now,
    };
    this.locationService.logToTrackJS(_gpsReport, gpsReport);
    update.gpsReports.push(gpsReport);
    await this.addPendingUpdate(update);
  }

  async getFlagStopPassInformation(passId: string): Promise<PassDetails> {
    const currentGps = this.data.gpsReport;
    const passInformationRequest: PassInformationRequestObject = {
      sessionId: this.data.sessionId,
      rideId: 0,
      passId: passId,
      numberOfchildren: 0,
      numberOfEscorts: 0,
      latitude: currentGps.latitude,
      longitude: currentGps.longitude,
    };

    const response = await this.proxy.passInformation(passInformationRequest);
    return response;
  }

  async getPassInformation(passId: string, job: Job): Promise<PassDetails> {
    const gpsReport = this.data.gpsReport;
    const passInformationRequest: PassInformationRequestObject = {
      sessionId: this.data.sessionId,
      rideId: job.rideId,
      passId: passId,
      numberOfchildren: job.numberOfChildren,
      numberOfEscorts: job.escorts,
      latitude: gpsReport.latitude,
      longitude: gpsReport.longitude,
    };

    const response = await this.proxy.passInformation(passInformationRequest);
    return response;
  }

  async isTripSync(customerId: number): Promise<Boolean> {
    return this.proxy.tripSync(customerId);
  }

  async startRoute(routeId: number): Promise<RouteUpdate> {
    try {
      const _gpsReport = this.data.gpsReport;
      const now = new Date();
      const gpsReport: GpsReport = {
        ..._gpsReport,
        dateTimeReported: now,
        occuranceDate: now,
      };
      this.locationService.logToTrackJS(_gpsReport, gpsReport);
      const routeUpdate = await this.proxy.startRoute(routeId, gpsReport);
      return this.routeSetup(routeUpdate);
    } catch (error) {
      this.errorHandler.handleError(error);
      return null;
    }

  }

  async previewRoute(routeId: number): Promise<RouteUpdate> {
    const routeUpdate = await this.proxy.routePreview(routeId);
    return this.routeSetup(routeUpdate);
  }

  async completePreTripInspection(inspectionItems: InspectionItem[]) {
    await this.performNonServicePeriod(NonServicePeriodActionEnum.End, NonServicePeriodTypeEnum.PreTrip);
    await this.addInspectionReport(inspectionItems, NonServicePeriodTypeEnum.PreTrip);
  }

  public startSendingUpdates(): Observable<void> {
    return from(this.storageService.ready()).pipe(
      switchMap(() => this.gpsReportInterval),
      switchMap(() => firstValueFrom(this.data.gpsReport$)),
      switchMap(gpsReport => Promise.all([
        this.gpsReport(gpsReport).catch(() => {}),
        this.sendDataToAVL(gpsReport).catch(() => {}),
      ])),
      catchError(err => {console.error(err); return EMPTY; }),
      map(() => {}),
    );
  }

  private async sendDataToAVL(gpsReport: GpsReport): Promise<any> {
    const { customerId } = this.data.localSettings;
    const { vehicleId, id: routeId, driverId } = this.data.route || {};
    if (!customerId || !vehicleId || !routeId || !driverId) { return; }
    const dataToReportToAvl = this.locationService.getAvlReport({gpsReport, customerId, route: this.data.route, device: this.data.device });
    await firstValueFrom(this.proxy.sendLocation(dataToReportToAvl));
  }
  //Determine if job type is a string and if so type cast to an int
  jobTypeToInt(jobType: any): number {
    if (typeof jobType === 'string') return +jobType;
    else return jobType;
  }

  private setPostTripEnabled(route: Route): NonServicePeriod {
    if (!route.postTrip) { return null; }
    const postTrip = { ...route.postTrip };
    if (environment.features.requireEmptyListForPostTrip) postTrip.isEnabled = route.jobs.length == 0;
    else postTrip.isEnabled = true;
    return postTrip;
  }

  private setBreakEnabled(route: Route): NonServicePeriod {
    if (!route.break) { return null; }
    const routeBreak = { ...route.break, isEnabled: true };
    return routeBreak;
  }

  private async routeSetup(routeUpdate: RouteUpdate): Promise<RouteUpdate> {
    const { route, messages } = routeUpdate;

    // Normalize Data
    route.jobs = route.jobs.map(job => ({
      ...job,
      jobType: this.jobTypeToInt(job.jobType),
    }));

    // handle scheduledFlagStops
    // sort the flagStops
    route.scheduledFlagStops?.sort((o1: ScheduledFlagStop, o2: ScheduledFlagStop) => {
      return (o1.departureTime > o2.departureTime) ? 1 : ((o1.departureTime < o2.departureTime) ? -1 : 0);
    });

    // handle nonServicePeriods
    if (route.preTrip && !route.preTrip.hasBeenCompleted) {
      route.preTrip = { ...route.preTrip, nonServicePeriodType: NonServicePeriodTypeEnum.PreTrip };
    }

    if (route.break && !route.break.hasBeenCompleted) {
      route.break = { ...route.break, nonServicePeriodType: NonServicePeriodTypeEnum.Break };
      route.break = this.setBreakEnabled(route);
    }

    if (route.postTrip && !route.postTrip.hasBeenCompleted) {
      route.postTrip = { ...route.postTrip, nonServicePeriodType: NonServicePeriodTypeEnum.PostTrip };
      route.postTrip = this.setPostTripEnabled(route);
    }

    //  Notify driver of changes and then set route to data store
    const notifications = this.getUpdateNotifications(this.data.route, route);
    this.data.set('routeNotifications', notifications);

    this.data.set('route', route);

    //  set messages
    const _previousMessages = this.data.messages;
    const _messages = (messages || []).map(message => ({ ...message,  createdDate: new Date(message.createdDate), messageResponseType: Number(message.messageResponseType)}));
    const priorityMessages = _messages.filter(m => m.messageResponseType === MessageResponseEnum.Acknowledge && !_previousMessages.find(pm => pm.id === m.id));

    this.data.set('messages', _messages);
    this.data.set('priorityMessages', priorityMessages);

    return { ...routeUpdate, route };
  }

  private async addPendingUpdate(update: RouteStatusUpdate) {
    const currentTicks = new Date().getTime() + Math.floor(Math.random() * 9999) + 1;
    const postTrip = this.setPostTripEnabled(this.data.route);
    const routeBreak = this.setBreakEnabled(this.data.route);
    this.data.set('route', { ...this.data.route, postTrip, break: routeBreak });
    this._pendingUpdatesExist = true;
    await this.storageService.set(this.pendingUpdatesStorageKey + currentTicks, JSON.stringify(update));
    await this.sendPendingUpdates();
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utilityService.scrollTop$.next(true);
    }
  }

  private async sendPendingUpdates() {
    // if we are already processing updates then don't do it again at the same time
    if (this.currentlyProcessingUpdates) {
      return;
    }

    try {
      // since we are sending updates - if the timeout is active, clear it.
      clearTimeout(this.timer);
      this.currentlyProcessingUpdates = true;
      // get the pending updated from storage and move them to in process
      console.debug('checking for updates to send to server');
      this._pendingUpdatesExist = await this.pendingUpdatesKeysExist();

      // record if updates were sent successfully because we don't want to get updates from the server
      // if we have updates we couldn't send
      // if there are no updates to send then we are ok to get updates from the server
      let updatesSuccessfullySent = !this._pendingUpdatesExist;

      const more = await this.moreWorkToDoHere();
      if (this._pendingUpdatesExist && more) {
        console.debug("PendingUpdates, but updates are paused.");
      } else if (this._pendingUpdatesExist) {
        console.debug('Pending updates found');
        // remove the pending updates from local storage
        const pendingUpdates = await this.removePendingUpdates();

        const numGpsReportsToSend = pendingUpdates.gpsReports ? pendingUpdates.gpsReports.length : 0;

        // store them in pending updates just in case the client dies before the response is completed
        // this is just coop over complicating for edge cases
        await this.storageService.set(this.inProcessUpdatesStorageKey, JSON.stringify(pendingUpdates));

        // actually send the pending update to the server
        try {
          const response = await this.proxy.routeStatusUpdate(pendingUpdates);
          console.debug(
            `Successfully sent updates to server (${numGpsReportsToSend} gps reports). Clearing local storage`);
          updatesSuccessfullySent = true; // there were updates to send and we just sent them

          // reset the retry count and message id because the message was processed successfully.
          this.retryCount = 0;
          this.messageId = this.utilityService.generateGuid();

          this.needUpdateFromServer = response.needUpdateFromServer;

          if (response.loggedOutDate && this.data.driverIsActivelyReporting) {
            // the response says the driver is logged out but the driver is still active on the route
            console.debug('User is logged out. Redirecting to login page..', response);
            // todo: tell the user they are signed out and return them to the login page
            this.onUserLoggedOut.next(response);
            // stop making new update reports since the driver is now logged out and should be back on the login
            this.data.set('driverIsActivelyReporting', false);
          }
        } catch (e) {
          if ((e.name === 'HttpErrorResponse'
            && e.status !== null && e.status !== undefined
            && e.status === 0)
            || e.name === 'NetworkError') {
            // there was a network error between the client and server
            // in this case there is no reason to increment retry count.
            // we can just keep trying until the network is available again
            await this.addPendingUpdate(pendingUpdates);
            console.debug(
              'Attempted to send pending updates to the server but there is no available network connection');
          } else {
            // its not a network error and the server didn't confirm it got it
            // we need to try sending this again and log the error
            // increment the retry count - so that a delay is created before retry
            this.retryCount++;

            await this.addPendingUpdate(pendingUpdates);
            console.warn('Unexpected error sending updates to server', e);
          }
        } finally {
          // this update is no longer in process. either we are done sending it or it is back in pending
          await this.storageService.remove(this.inProcessUpdatesStorageKey);
        }
      } else {
        console.debug('No pending updates found');
      }

      // if we need to get updates from the server
      if (this.needUpdateFromServer && this.data.driverIsActivelyReporting) {
        if (!updatesSuccessfullySent) {
          console.debug(
            'The server has indicated it has updates for the client but the client was unable to send the updates it ' +
            'has for the server. As a result, the client is not asking the server for updates');
        } else {
          this._pendingUpdatesExist = await this.pendingUpdatesKeysExist();
          if (!this._pendingUpdatesExist) {
            // there are either no additional updates on the client that need to be synced
            // or the only updates pending are gps reports
            console.debug('Requesting updates from server');
            const routeUpdate = await this.getRouteUpdate();
            console.debug('Updates received from server');
            // this will be null if there were no changes
            if (routeUpdate) {
              this.data.set('route', routeUpdate.route);
              this.data.set('noShowOptions', routeUpdate.noShowOptions || []);
              this.data.set('messages', routeUpdate.messages.map(message => ({ ...message,  createdDate: new Date(message.createdDate), messageResponseType: Number(message.messageResponseType)})));
            }
            this.needUpdateFromServer = false;
          } else {
            console.debug(
              'the server has indicated it has updates for the client but the client has additional '
              + 'pending updates that need to be sent to the server first');
          }
        }
      }

    } catch (e) {
      console.error('A completely unexpected error while attempting to send updates', e);
    } finally {
      this._pendingUpdatesExist = await this.pendingUpdatesKeysExist();
      // If there are remaining pending updates or the driver is actively reporting
      // restart the timer
      if (this.data.driverIsActivelyReporting || this._pendingUpdatesExist) {
        console.debug('Continuing to check for updates to send to the server in ' +
          this.serverUpdateInterval + ' milliseconds');

        // raise the timer interval up to the max interval
        let retryInterval = this.serverUpdateInterval * Math.pow(2, this.retryCount);
        retryInterval = (retryInterval >= this.maxServerUpdateInterval) ? this.maxServerUpdateInterval : retryInterval;

        this.timer = setTimeout(this.sendPendingUpdates.bind(this), retryInterval);
      } else {
        console.debug('The driver is no longer active on the route. Stopping the check for updates');
      }
      this.currentlyProcessingUpdates = false;
    }
  }

  private async removePendingUpdates(): Promise<RouteStatusUpdate> {
    let keys = await this.storageService.keys();
    keys = keys.filter(key => key.startsWith(this.pendingUpdatesStorageKey));
    if (keys.length === 0) { return null; }

    // set the messageId if not already set.
    this.messageId = this.messageId || this.utilityService.generateGuid();

    // assume all pending updates have the same sessionId
    const rawPendingUpdate = await this.storageService.get(keys[0]);
    const pendingUpdate: RouteStatusUpdate = JSON.parse(rawPendingUpdate);
    const blankUpdate: RouteStatusUpdate = createNewStatusUpdate(pendingUpdate?.sessionId);
    blankUpdate.retryCount = this.retryCount;
    blankUpdate.messageId = this.messageId;

    // merge all of the sessions in storage currently into one RouteStatusUpdate
    for (let i = 0; i < keys.length; i++) {
      const update = await this.storageService.get(keys[i]);
      const parsed: RouteStatusUpdate = JSON.parse(update);
      concatStatusUpdates(blankUpdate, parsed);
      await this.storageService.remove(keys[i]);
    }

    return blankUpdate;
  }

  private async pendingUpdatesKeysExist(): Promise<boolean> {
    let keys = await this.storageService.keys();
    keys = keys.filter(key => key.startsWith(this.pendingUpdatesStorageKey));
    return keys.length > 0;
  }

  private async moreWorkToDoHere(): Promise<boolean> {
    let arrivals = 0;
    let performs = 0;

    let keys = await this.storageService.keys();
    keys = keys.filter(key => key.startsWith(this.pendingUpdatesStorageKey));
    for (const key of keys) {
      const update = await this.storageService.get(key);
      const parsed: RouteStatusUpdate = JSON.parse(update);
      arrivals += parsed.jobArrivals.length;
      performs += parsed.jobPerforms.length;
    };
    if (arrivals > 0 && arrivals != performs && environment.features.combineJob) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * handles required update notification
   * @returns whether the user can proceed with login in
   */
  handleUpdateRequired({ daysRemainingBeforeUpgradeIsRequired, expirationNotice }: LoginResponse) {
    if (daysRemainingBeforeUpgradeIsRequired === null || daysRemainingBeforeUpgradeIsRequired > 0) { return false; }

    const storeAccessMessage = `<br><br>${this.translateService.translate('LABEL.doNotHavePermission')} `
    + `${!this.deviceService.isIos ? 'Play' : 'App'} ${this.translateService.translate('LABEL.notifyAdmin')}.`;

    this.versionService.forceUpdateBeforeLogin = true;
    this.versionService.showPlayStoreUpgradeButton = true;
    this.versionService.upgradeMessage =
      this.translateService.translate('UPDATE.upgrade')
      + `${this.translateService.translate(!this.deviceService.isIos ? 'UPDATE.playStore' : 'UPDATE.appStore')}.\r\n`
      + (expirationNotice ? expirationNotice + '\r\n' : '')
      + `              ${storeAccessMessage}`;

    // login is denied
    return true;
  }

  handleImminentUpdate({ daysRemainingBeforeUpgradeIsRequired, expirationNotice }: LoginResponse): null | string {
    if (!daysRemainingBeforeUpgradeIsRequired) { return null; }

    const storeAccessMessage = `<br><br>${this.translateService.translate('LABEL.doNotHavePermission')} `
    + `${!this.deviceService.isIos ? 'Play' : 'App'} ${this.translateService.translate('LABEL.notifyAdmin')}.`;

      this.versionService.showPlayStoreUpgradeButton = true;
      let daysRemaining = `${daysRemainingBeforeUpgradeIsRequired} ${this.translateService.translate('LABEL.days')}`;
      if (daysRemainingBeforeUpgradeIsRequired === 1) {
        daysRemaining = this.translateService.translate('LABEL.today');
      }
      this.versionService.upgradeMessage =
        (expirationNotice ?
          expirationNotice + '\r\n' :
          this.translateService.translate('UPDATE.immediateUpgrade'))
        + `            ${this.translateService.translate('UPDATE.unableToUse')} ${daysRemaining}.\r\n`
        + `            ${storeAccessMessage}`;
      return this.versionService.upgradeMessage;
    }

    /** Handle getting the notification to tell driver what has changed when the route updated */
    private getUpdateNotifications(oldRoute: Route, newRoute: Route): string[] {

      if (!oldRoute?.jobs || !newRoute?.jobs) { return []; }

      // Create update object for added, changed, removed jobs
      const updates = newRoute.jobs.reduce((acc, current) => {

        const previous = oldRoute.jobs.find(previous =>
            (previous.rideId === current.rideId && previous.jobType === current.jobType)
            || (previous.flagStopId && current.flagStopId && previous.flagStopId === current.flagStopId));

        //  the only properties we notify of changes are location and scheduled time
        const isIdentical = previous
          ? previous.scheduledTime === current.scheduledTime && JSON.stringify(previous.location) === JSON.stringify(current.location)
          : false;

        //  Job has not changed
        if (previous && isIdentical) { return acc; }

        //  Job existed and has changed
        if (previous) {
          return { ...acc, changed: [ ...acc.changed, { previous, current }]};
        }

        //  Job is new
        return { ...acc, added: [ ...acc.added, current ]};
      }, {
        added: [],
        //  Job does not exist in new array
        removed: oldRoute.jobs.filter(previous => !newRoute.jobs.find(current =>
          (current.rideId === previous.rideId && current.jobType === previous.jobType)
          || (previous.flagStopId && current.flagStopId && previous.flagStopId === current.flagStopId))),
        changed: [],
      });

      //  Create array of notifications

      const added = updates.added.reduce((acc, job: Job) => {
        const unscheduled = this.translateService.translate('LABEL.unscheduled');
        const riderName = this.utilityService.handleNameForNotification(job.riderFirstName, job.riderLastName);

        // If dropOff ignore, we already dealt with this on the pickup
        if (job.jobType === JobType.DropOff) { return acc; }

        const dateTime = job.scheduledTime ? DateTime.fromISO(job.scheduledTime as any) : null;
        const jobDateString = dateTime && dateTime.toFormat('HH:mm') !== '00:00' ? dateTime.toFormat('HH:mm') : unscheduled;

        const message = `${this.translateService.translate('LABEL.pickupAndDropoff')} ${this.translateService.translate('LABEL.for')} ${riderName} ${this.translateService.translate('LABEL.at')} ${jobDateString} ${this.translateService.translate('LABEL.added')}`;
        return [ ...acc, message ];
      }, []);

      const removed = updates.removed.reduce((acc, job: Job) => {
        const unscheduled = this.translateService.translate('LABEL.unscheduled');
        const riderName = this.utilityService.handleNameForNotification(job.riderFirstName, job.riderLastName);

        // If dropOff ignore, we already dealt with this on the pickup
        if (job.jobType === JobType.DropOff) { return acc; }

        const dateTime = job.scheduledTime ? DateTime.fromISO(job.scheduledTime as any) : null;
        const jobDateString = dateTime && dateTime.toFormat('HH:mm') !== '00:00' ? dateTime.toFormat('HH:mm') : unscheduled;

        const message = `${this.translateService.translate('LABEL.pickupAndDropoff')} ${this.translateService.translate('LABEL.for')} ${riderName} ${this.translateService.translate('LABEL.at')} ${jobDateString} ${this.translateService.translate('LABEL.removed')}`;
        return [ ...acc, message ];
      }, []);

      const changed = updates.changed.reduce((acc, { previous, current}: { previous: Job, current: Job }) => {
        const riderName = this.utilityService.handleNameForNotification(current.riderFirstName, current.riderLastName);
        const jobTypeString = current.jobType === JobType.DropOff ? this.translateService.translate('LABEL.dropoffNoSpace') : this.translateService.translate('LABEL.pickup');

        //  Handling a change in scheduled time
        if (previous.scheduledTime !== current.scheduledTime) {
          let message = '';

          const previousDateTime = previous.scheduledTime ? DateTime.fromISO(previous.scheduledTime as any) : null;
          const currentDateTime = current.scheduledTime ? DateTime.fromISO(current.scheduledTime as any) : null;

          const unscheduled = this.translateService.translate('LABEL.unscheduled');
          const previousTime = previousDateTime && previousDateTime.toFormat('HH:mm') !== '00:00' ? previousDateTime.toFormat('HH:mm') : unscheduled;
          const currentTime = currentDateTime && currentDateTime.toFormat('HH:mm') !== '00:00' ? currentDateTime.toFormat('HH:mm') : unscheduled;

          //  this account for going from null to 00:00 in which case the time is not actually changed even though the data did
          if (previousTime === currentTime) { return acc; }

            const timeDiff = previousTime === unscheduled || currentTime === unscheduled
              ? Infinity
              : Math.abs(previousDateTime.diff(currentDateTime, 'minutes').minutes);

            //  only notify if time change is greater than 5 minutes
            if (timeDiff > 5) {
              message = `${jobTypeString} ${riderName} ${this.translateService.translate('LABEL.hasChangedFrom')} ${previousTime} ${this.translateService.translate('LABEL.to')} ${currentTime}`;
              acc = [ ...acc, message ];
            };

          return acc;
        }

        //  Handling a location change
        if (JSON.stringify(previous.location) !== JSON.stringify(current.location)) {
          const oldLocation = `${previous.location.address} ${previous.location.city} ${previous.location.state} ${previous.location.zipCode}`;
          const newLocation = `${current.location.address} ${current.location.city} ${current.location.state} ${current.location.zipCode}`;
          const message = `${jobTypeString} ${riderName} ${ this.translateService.translate('LABEL.addrChangedFrom')} `
            + `${oldLocation} ${this.translateService.translate('LABEL.to')} ${newLocation} `;
          acc = [ ...acc, message ];
        }

        return acc;

      }, []);
      //  make 1 notification out of all messages
      //  new Set to filter out any duplicates
      const messages = Array.from(new Set([ ...added, ...removed, ...changed ])).reduce((acc, message) => `${acc}${acc.length ? '<br>' : ''}${message}`, '' );
      //  don't send an a empty message
      return messages.length ? [ messages ] : [];
    }

    public async updateRouteSummaries(): Promise<void> {
      const summaries = await this.proxy.routeSummaries();
      this.data.set('routeSummaries', summaries);
    }

}
