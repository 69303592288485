import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, map, of, switchMap, takeUntil, takeWhile, tap } from 'rxjs';

import { AppData } from 'src/app/data';
import { ImageService } from 'src/app/services/image.service';
import { Job } from '../../models/job';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.page.html',
  styleUrls: ['./job-details.page.scss'],
})
export class JobDetailsPage implements OnInit, OnDestroy{

  @Input() job: Job;
  @Input() isDetailView = false;
  showClientId: boolean = environment.features.showClientId;

  private readonly data = inject(AppData);
  private readonly destroyed$ = new Subject<boolean>();

  public readonly showFare = environment.features.calculateJobFare;
  public image: string;
  public readonly hasNetwork$ = this.data.connectionStatus$.pipe(map(value => !!value.connected));
  public errorMessage = '';

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private imageService: ImageService,
    ) {
  }

  ngOnInit(): void {
    if (this.job.riderImageId) {
      this.getRiderImage().subscribe();
    }
  }

  getRiderImage(): Observable<string> {
    return this.hasNetwork$.pipe(
      takeWhile(hasNetwork => !hasNetwork, true),
      switchMap(hasNetwork => hasNetwork ?
        this.imageService.riderImage(this.data.sessionId, this.job.riderImageId)
        : EMPTY,
      ),
      takeWhile(image => !image, true),
      catchError(err => {
        console.error(err);
        this.errorMessage = 'An error has occurred fetching the image, please try again later.';
        return of('assets/img/no_image.svg');
      }),
      tap(res => this.image = res),
      takeUntil(this.destroyed$),
    );
  }

  async dismiss(data) {
    await this.modalCtrl.dismiss(data);
  }

  calculateJobFare() {
    if (environment.features.calculateJobFare)
      return this.job.additionalRideFare * (this.job.escorts + this.job.numberOfChildren) + this.job.rideFare;
    return this.job.rideFare;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
