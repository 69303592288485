<ion-app>
  <ion-menu type="overlay" side="end" contentId="menu-content" menuId="main-menu">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ 'TITLE.settings' | translate }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="closeMenu()" color="primary" fill="outline">
            {{ 'ACTION.close' | translate }}
            <ion-icon name="close" slot="end"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="main-menu">
        <ion-item *ngIf="data.driverIsActivelyReporting">
          <ion-button fill="clear" expand="block" (click)="logOutClicked()">
            <ion-icon class="ion-fw" name="log-out" slot="start"></ion-icon>
            {{ 'ACTION.logout' | translate }}
          </ion-button>
        </ion-item>
        <ion-item *ngIf="features.darkMode">
          <ion-icon class="ion-fw" name="moon" slot="start"></ion-icon>
          <ion-label style="font-size: 1.25rem;">Dark Mode</ion-label>
          <ion-toggle [(ngModel)]="isDark" (ionChange)="toggleChange($event.detail)" justify="space-between">
          </ion-toggle>
        </ion-item>
        <ion-item *ngIf="!data.driverIsActivelyReporting">
          <ion-button fill="clear" expand="block" (click)="customerClicked()">
            <div>
              <ion-icon class="ion-fw" name="person-outline" slot="start"></ion-icon>
              {{ 'ACTION.configureProvider' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-button fill="clear" expand="block" (click)="handleClick('deviceInfo')">
            <div>
              <ion-icon class="ion-fw" name="phone-portrait" slot="start"></ion-icon>
              {{ 'ACTION.deviceInfo' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="data.driverIsActivelyReporting$ | async">
          <ion-button fill="clear" expand="block" (click)="handleClick('gasReport')">
            <div>
              <ion-icon class="ion-fw" name="color-fill" slot="start"></ion-icon>
              {{ 'ACTION.gasReport' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <!-- <ion-item *ngIf="data.driverIsActivelyReporting$ | async" >
          <ion-button fill="clear" expand="block" class="main-menu-item" (click)="resetSession()">
            <ion-icon class="ion-fw" name='color-wand-outline' slot="start"></ion-icon>
            Reset Session
          </ion-button>
        </ion-item> -->
        <ion-item *ngIf="!data.driverIsActivelyReporting">
          <ion-button fill="clear" expand="block" (click)="checkForUpdates()">
            <div>
              <ion-icon class="ion-fw" name="refresh" slot="start"></ion-icon>
              {{ 'ACTION.checkUpdates' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-button fill="clear" expand="block" (click)="handleClick('testGPS')">
            <div>
              <ion-icon class="ion-fw" name="locate" slot="start"></ion-icon>
              {{ 'ACTION.testGPS' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-button fill="clear" expand="block" (click)="emailLogClicked()">
            <div>
              <ion-icon class="ion-fw" name="mail" slot="start"></ion-icon>
              Email Log
            </div>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-button fill="clear" expand="block" (click)="trackClicked()">
            <div>
              <ion-icon class="ion-fw" name="send-outline" slot="start"></ion-icon>
              Send Remote Log
            </div>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="data.driverIsActivelyReporting">
          <ion-button fill="clear" expand="block" (click)="cancelAccountClicked()" [disabled]="cancellingAccount">
            <div>
              <ion-icon class="ion-fw" name="alert-circle-outline" slot="start"></ion-icon>
              {{ 'LABEL.cancelAccount' | translate }}
            </div>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="showFairmaticItem">
          <ion-button fill="clear" expand="block" (click)="handleClick('fairmatic')">
            <div>
              <ion-icon class="ion-fw" name="beer" slot="start"></ion-icon>
              {{ 'ACTION.fairmatic' | translate }}
            </div>
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="menu-content"></ion-router-outlet>
  <!-- <app-environment-picker *ngIf="!deviceService.isProductionMobileDevice"></app-environment-picker> -->
</ion-app>
